import React from 'react';
import Chart from 'react-apexcharts';

function AreaChart({ height = 120, areaGraphData }) {
  if (!areaGraphData) return null;

  const seriesData = areaGraphData?.seriesData || []
  const categories = areaGraphData?.categories || []

  const seriesData12 = [
    {
      name: 'Systolic',
      data: [0, 10, 25, 50, 49, 60, 20],
    },
  ];

  const chartOptions = {
    chart: {
      type: 'area',
      height: height,
      fontFamily: 'inherit',
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#3b82f6', '#10b981'],
    },
    xaxis: {
      type: 'datetime',
      categories,
      categories12: [
        '2024-01-01',
        '2024-02-01',
        '2024-03-01',
        '2024-04-01',
        '2024-05-01',
        '2024-06-01',
        '2024-07-01',
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd',
      },
    },
  };

  return (
    <div className="area-chart" style={{ maxWidth: '100%', maxHeight: height }}>
      <Chart
        options={chartOptions}
        series={seriesData}
        type="area"
        height={height}
      />
    </div>
  );
}

export default AreaChart;
