import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';

const DateAndName = ({ date, name , episodeId}) => {
  const { t } = useSelector(selectLanguage);

  return (
    <div className="cpCardBg flex flex-col gap-2 p-4">
      <span className="titleDate">{date}</span>
      <span className="titleDoc">{name} <span>({t.EpisodeId}: {episodeId})</span></span>
    </div>
  );
};

export default DateAndName;
