import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import { formatDate, getTValue } from '../../../translations/common';
import Medications from './medications';
import Reports from './reports';
import Vitals from './vitals';
import Prescriptions from './prescriptions';
import Invoices from './invoices';
import DateAndName from './DateAndName';

import './completedAppointment.css';

const CompletedAppointment = ({ appointment }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { date } = appointment;
  const fDate = formatDate(date, isRtl);

  return (
    <div className="col-span-2 flex gap-2 flex-col rounded-[10px] mngapt max-[767px]:grid-cols-1 max-[1199px]:grid-cols-1 max-[1199px]:col-span-1 gap-2 grid-cols-2">
      <DateAndName
        date={`${fDate.monthDate} ${fDate.monthNameShort} ${fDate.year}`}
        name={getTValue(
          appointment,
          'arabicProviderDescription',
          'careProviderDescription',
          isRtl
        )}
        episodeId = {appointment.id}
      />
      <div className="grid h-full max-[767px]:grid-cols-1 max-[1199px]:grid-cols-1 gap-6 grid-cols-2">
        <div className="cpCardBg cpCardScroll p-2 flex flex-col flex-1 gap-2 max-[767px]:min-h-max max-[1199px]:min-h-max">
          <Vitals />
          <Prescriptions />
          <Reports />
          <Invoices />
        </div>
        <div className="cpCardBg cpCardScroll p-2 flex flex-col flex-1 gap-2">
          <Medications />
        </div>
      </div>
    </div>
  );
};

export default CompletedAppointment;
