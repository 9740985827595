import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FeedbackForm from '.';
import { modalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';

import '../Modal/style.css';

const FeedBackModal = (props) => {
  const { mainClass } = props;
  const dispatch = useDispatch();
  const { modalType } = useSelector((state) => state.modal);
  const { t, isRtl } = useSelector(selectLanguage);
  const [showModalClass, setShowModalClass] = useState(null);

  const ModelCloseHandler = () => {
    setShowModalClass(false);
    setTimeout(() => {
      dispatch(modalActions.closeModal());
    }, 200);
  };

  const stopPropagationHandler = (e) => {
    e.stopPropagation();
  };

  const testingFunc = () => {
    setTimeout(() => {
      setShowModalClass(true);
    }, 200);
  };

  const isOpen = modalType === 'FeedbackSurveyModal';

  if (isOpen) {
    testingFunc();
  }

  return isOpen ? (
    <Fragment>
      <div
        dir={isRtl ? 'rtl' : ''}
        className={`modal fade ${mainClass ? mainClass : ''} ${
          showModalClass ? 'show' : ''
        }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="overlay"
        onClick={ModelCloseHandler}
        style={{
          display: `${isOpen ? 'block' : ''}`,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background
          zIndex: 1050, // Ensure the modal appears above other elements
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Center the content vertically and horizontally
        }}
      >
        <div
          className={`md rounded-3xl bg-white`}
          onClick={stopPropagationHandler}
          style={{
            maxWidth: '500px', // Adjust the width of the modal
            width: '100%',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <FeedbackForm />
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default FeedBackModal;
