import React, { useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';
import { formatVitalData } from '../../../../utilFunctions/Vitals';
import Loader from '../../../UI/Loader';
import useFetchVitalsByEpisode from '../../../../utilFunctions/completedAppointment/useFetchVitalsByEpisode';
import CollapsibleWrapper from '../../CollapsibleWrapper';
import VitalItem from './VitalItem';

const Vitals = () => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { data: vitalsList, loading, error } = useFetchVitalsByEpisode();
  const formatedData = formatVitalData(vitalsList);

  return (
    <CollapsibleWrapper title={t.Vitals}>
      <div className="flex flex-col">
        {vitalsList?.length > 0 && (
          <>
            <ul>
              {formatedData && formatedData.length > 0 ? (
                formatedData.map((item, index) => (
                  <li key={index} className="mb-2">
                    <VitalItem index={index} vital={item} vitalsList/>
                  </li>
                ))
              ) : (
                <div className="w-full flex justify-center">
                  <span className="mt-8">{t.NoRecord}</span>
                </div>
              )}
            </ul>
          </>
        )}
        {loading && (
          <div className="w-full justify-center">
            <Loader roundLoader />
          </div>
        )}
        {!loading && vitalsList?.length === 0 && (
          <div className="w-full flex justify-center">
            <span className="mt-8">{t.NoRecord}</span>
          </div>
        )}
      </div>
    </CollapsibleWrapper>
  );
};

export default memo(Vitals);
