import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';

export const REPORT_TYPES = {
  LAB: 'laboratory-results',
  RADI: 'radiology-results',
  OPER: 'operative-reports',
  CARD: 'cardiology-results',
  SICK: 'sick-leave-reports',
  DISCHARGE: 'discharge-summary-reports',
};

const formatLabsResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.order_list || [];

  const formatDataGroup = (data) => {
    const sorted = data
      .slice()
      .sort((a, b) => moment(b.collected_date).diff(moment(a.collected_date)));

    const groupedData = sorted.reduce((acc, curr) => {
      const labEpisodeNumber = curr.labepisode_number;
      const existingGroupIndex = acc.findIndex(
        (group) => group[0]?.labepisode_number === labEpisodeNumber
      );
      if (existingGroupIndex === -1) {
        acc.push([curr]);
      } else {
        acc[existingGroupIndex].push(curr);
      }
      return acc;
    }, []);

    return groupedData;
  };

  const formatReportData = (report) => {
    return {
      id: report?.order_id,
      smartReport: report?.smart_report,
      pdfDownloadId: report?.specimen_number,
      collectedDate: report?.collected_date,
      collectedTime: report?.collected_time,
      status: 'Approved',
      reportType: REPORT_TYPES.LAB,
      doctor: report?.ordered_doctor,
      result: {
        resultId: report?.result_list[0]?.result_id || '',
        description: report?.result_list[0]?.test_item_description || '',
        value: report?.result_list[0]?.result_value || '',
        unit: report?.result_list[0]?.result_units || '',
        range: report?.result_list[0]?.result_normal_range || '',
        interpretation: report?.result_list[0]?.result_interpretation || '',
      },
      // results: report?.result_list?.map((result) => ({
      //   resultId: result?.result_id,
      //   description: result?.test_item_description,
      //   value: result?.result_value,
      //   unit: result?.result_units,
      //   range: result?.result_normal_range,
      //   interpretation: result?.result_interpretation,
      // })),
      labEpisode: report?.labepisode_number,
    };
  };

  if (listData?.length > 0) {
    const groupedData = formatDataGroup(listData);

    //this will get firstitem from eachgroup
    const itemArray = groupedData.map((group) => group[0]);
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatRadiologyResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.order_list || [];

  const formatReportData = (report) => {
    return {
      id: report?.order_id,
      smartReport: false,
      pdfDownloadId: report?.order_id,
      collectedDate: report?.result_date,
      collectedTime: report?.result_time,
      status: report?.order_status,
      reportType: REPORT_TYPES.RADI,
      doctor: report?.ordered_doctor,
      result: {
        resultId: '',
        description: report?.order_description || '',
        value: report?.authorized_user || '',
        unit: '',
        range: '',
        interpretation: '',
      },
      labEpisode: report?.order_id,
    };
  };

  if (listData?.length > 0) {
    const sortedByDateArray = listData
      .slice()
      .sort((a, b) => moment(b.collected_date).diff(moment(a.collected_date)));

    const itemArray = [...sortedByDateArray];
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatCardiologyResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.order_list || [];

  const cardItem = {
    order_id: '3250573||7',
    episode_number: 'EM0000044217',
    episode_id: '873855',
    order_description: 'ECG (Electrocardiogram)',
    ordered_doctor: 'JAHA NERMEEN',
    order_status: 'Verified',
    approval_status: 'NA',
    result_attached: 'false',
    authorized_user: '',
    result_date: '',
    result_time: '',
  };

  const formatReportData = (report) => {
    return {
      id: report?.order_id,
      smartReport: false,
      pdfDownloadId: report?.episode_id, // forDownload
      collectedDate: report?.result_date,
      collectedTime: report?.result_time,
      status: report?.order_status,
      reportType: REPORT_TYPES.CARD,
      doctor: report?.ordered_doctor,
      result: {
        resultId: '',
        description: report?.order_description || '',
        value: report?.authorized_user || '',
        unit: '',
        range: '',
        interpretation: '',
      },
      labEpisode: report?.episode_number,
    };
  };

  if (listData?.length > 0) {
    const sortedByDateArray = listData
      .slice()
      .sort((a, b) => moment(b.collected_date).diff(moment(a.collected_date)));

    const itemArray = [...sortedByDateArray];
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatSickResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.document_list || [];

  const cardItem = {
    mrn: null,
    reportType: null,
    hosp: null,
    oldData: null,
    document_id: '911931||13',
    authorized_user: 'Abdul Hameed Hassan',
    authorized_date: '2023-10-12',
    authorized_time: '14:53',
    clinic_code: '3029',
    clinic_description: 'Internal Medicine ',
    document_status: 'Authorised',
  };

  const formatReportData = (report) => {
    return {
      id: report?.document_id,
      smartReport: false,
      pdfDownloadId: report?.document_id, // forDownload
      collectedDate: report?.authorized_date,
      collectedTime: report?.authorized_time,
      status: report?.document_status,
      reportType: REPORT_TYPES.SICK,
      doctor: report?.authorized_user,
      result: {
        resultId: '',
        description: report?.order_description || '',
        value: report?.clinic_description || '',
        unit: '',
        range: '',
        interpretation: '',
      },
      labEpisode: report?.clinic_code,
    };
  };

  if (listData?.length > 0) {
    const sortedByDateArray = listData
      .slice()
      .sort((a, b) =>
        moment(b.authorized_date).diff(moment(a.authorized_date))
      );

    const itemArray = [...sortedByDateArray];
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatDischargeResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.document_list || [];

  const respItem = {
    documentId: '222352',
    authorizingUser: 'Bakr Faisal',
    authorizedDate: '2024-05-01',
    authorizedTime: '13:09',
    reportType: null,
    hosp: null,
    oldData: null,
  };

  const formatReportData = (report) => {
    return {
      id: report?.documentId,
      smartReport: false,
      pdfDownloadId: report?.documentId, // forDownload
      collectedDate: report?.authorizedDate,
      collectedTime: report?.authorizedTime,
      status: null,
      reportType: REPORT_TYPES.DISCHARGE,
      doctor: report?.authorizingUser,
      result: null,
      labEpisode: null,
    };
  };

  if (listData?.length > 0) {
    const sortedByDateArray = listData
      .slice()
      .sort((a, b) => moment(b.authorizedDate).diff(moment(a.authorizedDate)));

    const itemArray = [...sortedByDateArray];
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatOperativeResponse = (apiData) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  const listData = apiData?.document_list || [];

  const formatReportData = (report) => {
    return {
      id: report?.document_id,
      smartReport: false,
      pdfDownloadId: report?.document_id, // forDownload
      collectedDate: report?.authorized_date,
      collectedTime: report?.authorized_time,
      status: null,
      reportType: REPORT_TYPES.OPER,
      doctor: report?.authorized_user,
      result: null,
      labEpisode: null,
    };
  };

  if (listData?.length > 0) {
    const sortedByDateArray = listData
      .slice()
      .sort((a, b) => moment(b.collected_date).diff(moment(a.collected_date)));

    const itemArray = [...sortedByDateArray];
    fomatedData = itemArray.map(formatReportData);
  }

  return fomatedData;
};

const formatResponseByType = (apiData, type) => {
  let fomatedData = [];
  if (!apiData) return fomatedData;

  if (type === REPORT_TYPES.LAB) fomatedData = formatLabsResponse(apiData);
  if (type === REPORT_TYPES.RADI)
    fomatedData = formatRadiologyResponse(apiData);
  if (type === REPORT_TYPES.OPER)
    fomatedData = formatOperativeResponse(apiData);
  if (type === REPORT_TYPES.SICK) fomatedData = formatSickResponse(apiData);
  if (type === REPORT_TYPES.CARD)
    fomatedData = formatCardiologyResponse(apiData);
  if (type === REPORT_TYPES.DISCHARGE)
    fomatedData = formatDischargeResponse(apiData);

  return fomatedData;
};

export const getReportByType = async (
  reportType,
  episodeId,
  oldData = 0,
  pageNo = 1
) => {
  const hospitalCode = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).code
    : 'IMC';
  const hospitalId = localStorage.selectedHopitalInfo
    ? JSON.parse(localStorage.selectedHopitalInfo).id
    : '1';

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  // for new reports
  let endpoint = `api/reports/${reportType}?patientId=${
    userInfo?.patientId
  }&page=${pageNo}&oldData=${0}&hospitalCode=${hospitalCode}`;

  // for history reports
  if (oldData === 1) {
    endpoint = `api/reports/${reportType}?patientId=${
      userInfo?.mrn
    }&page=${pageNo}&oldData=${1}`;

    if (reportType === REPORT_TYPES.LAB) {
      endpoint = `${endpoint}&hospitalCode=${hospitalId}`;
    } else {
      endpoint = `${endpoint}&hosp=${hospitalId}`;
    }
  }

  try {
    const data = await baseFetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return data;
  } catch (error) {
    console.error(`Error fetching report ${reportType}:`, error);
    throw error;
  }
};

export const getReportApi = async (report) => {
  const { type, endpoint } = report;

  try {
    const data = await baseFetch(endpoint);

    return data;
  } catch (error) {
    console.error(`Error fetching report ${type}:`, error);
    throw error;
  }
};

function useFetchReportsByEpisode() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  const hospitalCode = useSelector((state) => state.hospitalInfo.code);
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  let episodeId = selectedManageAppointment?.id || '';
  let AllReports = [
    {
      type: REPORT_TYPES.LAB,
      endpoint: `api/reports/laboratory-results?patientId=${userInfo?.patientId}&page=1&hospitalCode=${hospitalCode}&episodeId=${episodeId}&inPatient=true&resultDate&resultTime&labEpisodeNumber`,
    },
    {
      type: REPORT_TYPES.RADI,
      endpoint: `api/reports/radiology-results?patientId=${userInfo?.patientId}&hosp=IMC&episodeId=${episodeId}&inPatient=1&resultDate&resultTime`,
    },
    // {
    //   type: REPORT_TYPES.MED,
    //   endpoint: `api/reports/medical-report?patientId=${userInfo?.patientId}&hospitalCode=${hospitalCode}&inPatient=1&episodeId=${episodeId}&authorizedDate&authorizedTime`,
    // },
    {
      type: REPORT_TYPES.OPER,
      // endpoint: `api/reports/operative-reports?patientId=22&hospitalCode=IMC&inPatient=1&episodeId&authorizedDate=&authorizedTime`,
      endpoint: `api/reports/operative-reports?patientId=${userInfo?.patientId}&hospitalCode=${hospitalCode}&inPatient=1&episodeId=${episodeId}&authorizedDate=&authorizedTime`,
    },
    {
      type: REPORT_TYPES.CARD,
      endpoint: `api/reports/cardiology-results?patientId=${userInfo?.patientId}&hospitalCode=${hospitalCode}&episodeId=${episodeId}&inPatient=0&resultDate&resultTime`,
    },
    {
      type: REPORT_TYPES.SICK,
      endpoint: `api/reports/sick-leave-reports?patientId=${userInfo?.patientId}&hospitalCode=${hospitalCode}&inPatient=1&episodeId=${episodeId}&authorizedDate&authorizedTime`,
    },
    {
      type: REPORT_TYPES.DISCHARGE,
      endpoint: `api/reports/discharge-summary-reports?patientId=${userInfo?.patientId}&hospitalCode=${hospitalCode}&inPatient=1&episodeId=${episodeId}&authorizedDate=2023-10-04&authorizedTime`,
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    const reportPromises = AllReports.map(async (report) => {
      const { type } = report;
      try {
        const reportData = await getReportApi(report);

        setData((prevData) => [
          ...prevData,
          ...formatResponseByType(reportData, type),
        ]);
      } catch (err) {
        console.error(`Error fetching report ${type}:`, err);
        setError(err);
      }
    });

    await Promise.allSettled(reportPromises);
    setLoading(false);
  };

  // useEffect(() => {
  //   if (selectedManageAppointment?.id) {
  //     fetchData();
  //   }
  // }, [hospitalId, page, selectedManageAppointment]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      fetchData();
    }
  }, [page]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      setLoading(true)
      setData([])
      setPage(1)
    }
  }, [hospitalId,selectedManageAppointment]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchReportsByEpisode;
