import React, { useEffect, useRef, useState } from 'react';
import '../components/Otp/style.css';
import OtpInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';
import { LoginTimeStamp } from '../utilFunctions/LoginTimeStamp';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../store';
import { envVariables } from '../config';
import Loader from '../components/UI/Loader';
import { selectLanguage } from '../store/languageSlice';
import { getSelectLanguage } from '../utilFunctions/getCurrentSelectLanguage';
import { languageApiUpdate } from '../utilFunctions/languageChangeApi';

const OtpVerify = () => {
  const { apiRoute } = envVariables;
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const redirectedUrl = useSelector(state => state.authentication.redirectedUrl);
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [isError, setError] = useState(false)
  const [showVerifyingLoader, setShowVerifyingLoader] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [shouldFocus, setShouldFocus] = useState(true);
  const inputRefs = useRef([]);
  useEffect(() => {
    let intervalId;
    if (minutes === 0 && seconds === 0 && isResendDisabled) {
      setIsResendDisabled(false);
    } else if (minutes !== 0 || seconds !== 0) {
      intervalId = setTimeout(() => {
        if (seconds === 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [minutes, seconds, isResendDisabled]);

  const handleClickResend = async () => {
    setError(false)
    setShowVerifyingLoader(true);
    setOtp('');
    inputRefs?.current[0]?.focus();
    let loginCreden = JSON.parse(localStorage.getItem('loginData'));
    if (loginCreden) {
      // Send request to generate OTP
      const loginReq = await fetch(`${apiRoute}auth-v2/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept-Language": getSelectLanguage(),
        },
        body: JSON.stringify({
          mrn: loginCreden.mrn,
          nationalId: loginCreden.nationalId,
        }),
      });
      if (!loginReq.ok) {
        const errorText = await loginReq.text();
        setShowVerifyingLoader(false);
        throw new Error(errorText || 'Unknown error in auth-v2/login');
      }
      let getResponse = await loginReq.json();
      if (getResponse.status === "Fail" && getResponse.message === "OTP Not Matched!") {
        // console.log('tokenRes', getResponse);
        setError(true)
        setIsResendDisabled(false
        )
        setMinutes(1)
        setSeconds(0)
        showVerifyingLoader(false)
      } else if (getResponse.status === true) {
        setMinutes(1);
        setSeconds(0);
        setIsResendDisabled(true);
      }
    }
    setShowVerifyingLoader(false);
    setShouldFocus(true);
  };

  const handleClickVerify = async (updatedOtp) => {
    const res = JSON.parse(localStorage.getItem('loginReq'));
    setShowVerifyingLoader(true);

    if (res !== null) {
      try {
        const res = JSON.parse(localStorage.getItem('loginReq'));
        const tokenReq = await fetch(`${apiRoute}auth-v2/login/validate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Accept-Language": getSelectLanguage(),
          },
          body: JSON.stringify({
            otp: updatedOtp || otp,
            consent: res.consent,
            userId: res.userId,
            user_type: 'USER',
            platform: 'web',
            deviceToken: 'gG8aBbFmyMp6bgpJPo7ssrALKRBCg6dXj8SNjfbz',
            hosp: '1',
          }),
        });

        if (!tokenReq.ok) {
          setShowVerifyingLoader(false);
          throw new Error(`Error validating login: ${tokenReq.statusText}`);
        }

        const tokenRes = await tokenReq.json();
        // console.log('tokenRes', tokenRes);
        if (!tokenRes || tokenRes.status === "Fail") {
          // console.log('tokenRes', tokenRes);
          setError(true)
          setIsResendDisabled(false)
          setMinutes(1)
          setSeconds(0)
          showVerifyingLoader(false)
        } else {
          localStorage.setItem('userInfo', JSON.stringify(tokenRes));
          localStorage.removeItem('previousUserInfo');
          localStorage.removeItem('loginReq');
          languageApiUpdate();
          LoginTimeStamp(tokenRes.accessToken, true);

          dispatch(authenticationActions.authenticateToggle(true));
          // if(redirectedUrl) navigate(redirectedUrl);
          // navigate('/book-appointment');
          navigate('/dashboard');
        }



        // navigate('/');
        // setShowVerifyingLoader(false);
        // navigate("/manage-appointment");
      } catch (error) {
        console.error(error);
        setShowVerifyingLoader(false);
      }
    } else {
      let getRegisterData = JSON.parse(
        localStorage.getItem('registrationResponse')
      );

      const otpVerify = await fetch(
        `${apiRoute}auth-v2/registration/validate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Accept-Language": getSelectLanguage(),
          },
          body: JSON.stringify({
            otp: '5342',
            mobileNumber: getRegisterData?.mobileNumber,
            hosp: '1',
          }),
        }
      );
      if (!otpVerify.ok) {
        throw new Error(`Error validating login: ${otpVerify.statusText}`);
      }
      let getResponseOtpVerify = await otpVerify.json();

      if (getResponseOtpVerify.status === true) {
        const tokenVerify = await fetch(
          `${apiRoute}api/patient/task/registration/v6`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Accept-Language": getSelectLanguage(),
            },
            body: JSON.stringify({
              firstName: getRegisterData.firstName,
              firstNameAr: getRegisterData.firstName,
              fatherName: getRegisterData.middleName,
              fatherNameAr: getRegisterData.middleName,
              familyName: getRegisterData.lastName,
              familyNameAr: getRegisterData.lastName,
              hosp: 'IMC',
              docNumber: getRegisterData.idNumber,
              docExpiryDate: getRegisterData.IdExpiryDate,
              docType: getRegisterData.idType,
              dob: getRegisterData.dob,
              telMobile: getRegisterData.mobileNumber,
              platform: 'Web',
              gender: getRegisterData.gender,
              nationality: 'Saudi Arabian',
              addressEn: '',
              addressAr: '',
            }),
          }
        );

        if (!tokenVerify.ok) {
          throw new Error(`Error validating login: ${tokenVerify.statusText}`);
        }
        let getResponse = await tokenVerify.json();
        if (getResponse.status === true) {
          localStorage.removeItem('registrationResponse');
        }
      }else {
        setError(true)
          setIsResendDisabled(false)
          setMinutes(1)
          setSeconds(0)
          showVerifyingLoader(false)
      }
    }
  };

  const handleOnInputChange = async (data) => {
    setOtp(data);

    if (data && inputRefs.current[data.length]) {
      inputRefs.current[data.length].focus();
    }

    if (data?.length >= 4) {
      await handleClickVerify(data);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp.length > 0) {
      const newOtp = otp.slice(0, -1);
      setOtp(newOtp);
      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlegoBack = () => {
    navigate('/login'); // Navigate back
  };

  return (
    <div className="relative">
      <button
        onClick={() => handlegoBack()}
        className={`absolute left-32 top-16 z-10 ${isRtl ? 'right-32' : 'left-32'
          }`}
      >
        <p dir={isRtl ? 'rtl' : ''} className="gobackBtn">
          <img
            style={{ transform: `scaleX(${isRtl ? -1 : 1})` }}
            src={`${process.env.PUBLIC_URL}/images/Back.svg`}
          />
          {t.Back}
        </p>
      </button>
      <div className="Otp_container">
        <div className="Otp_box">
          <section className="Otp_Bpx">
            <div className="imc_logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/bigLogo.png`}
                alt="ImcLogo"
              />
            </div>
            <div className="OtpInput_Box">
              <h2>{t.OTPVerification}</h2>
              <p>{t.WeHaveSendAnOTPOnYourNumber}</p>
              <div dir='ltr'>
              <OtpInput
                value={otp}
                onChange={handleOnInputChange}
                numInputs={4}
                inputType="numeric"
                inputStyle={'inputStyle'}
                autoFocus={shouldFocus}
                renderInput={(props, index) => (
                  <input
                    {...props}
                    id={`otp-input-${index}`}
                    autoFocus={index === 0 && shouldFocus}
                    name={index}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                )}
              />
              </div>
              {isError && !showVerifyingLoader ? <span className='text-red-700'>{t.InvalidOtpisEntered}</span> : null}

              {/* {!showVerifyingLoader && ( */}
              <p className="resendCode_line">
                <span
                  className={
                    isResendDisabled
                      ? 'pointer_event_block'
                      : 'pointer_event_UnBlock'
                  }
                  onClick={handleClickResend}
                >
                  {t.ResendCode}
                </span>
                {isResendDisabled && (
                  <>
                    <span className="mx-1">{t.In}</span>
                    <span className="resendCode_timer">
                      {minutes.toString().padStart(2, '0')}:
                      {seconds.toString().padStart(2, '0')}
                    </span>
                  </>
                )}
              </p>
              {/* )} */}
              {showVerifyingLoader && (
                <div className="my-4">
                  <Loader roundLoader />
                </div>
              )}
            </div>
            {/* <div className="signBtnDiv">
              <button className="signIn_btn" onClick={handleClickVerify}>
                Sign In
              </button>
            </div> */}
            <p className="registerLink mt-8">
              <span>
                {t.NewToIMC} &nbsp; <Link to="/register">{t.Register}</Link>
              </span>
            </p>
          </section>
          <img
            src={`${process.env.PUBLIC_URL}/images/menu-banner-bottom.svg`}
            className="login_banner_bottm"
          />
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
