import { baseFetch } from '../config';
import { getSelectLanguage } from './getCurrentSelectLanguage';

export const languageApiUpdate = async () => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const accessToken = userInfo?.accessToken ?? '';

    if (!accessToken && accessToken == '') return;

    const endpoint = `api/patient/language`;
    const data = await baseFetch(endpoint, {
      method: 'POST',
      headers: {
        'Accept-Language': getSelectLanguage(),
        Authorization: `Bearer ${accessToken}`
      },
    });

    return data;
  } catch (error) {
    console.error('Error on Language', error);
    // throw error;
  }
};

