import React from 'react';
import { FaCheck } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../store/languageSlice';
import Tooltip from '../../UI/Sidebar/Tooltip';

import './completedAppointment.css';

const StatusItem = () => {
  const {t} = useSelector(selectLanguage)
  return (
    <Tooltip text={t.InsuranceTax}>
    <div className="statusContainer flex flex-row gap-2 px-1">
      <span className="statusTitle">{t.Approved}</span>
      <span className="statusIcon"><FaCheck /></span>
    </div>
    </Tooltip>
  );
};

export default StatusItem;
