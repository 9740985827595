import React from 'react';
import { FiDownload, FiCalendar } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';
import { showToast } from '../../../../store/toastSlice';
import { formatDate } from '../../../../translations/common';
import { downloadInvoice } from '../../../../utilFunctions/completedAppointment/useFetchInvoicesByEpisode';

const InvoiceCard = ({ index, item }) => {
  const { t, isRtl } = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const {
    episodeNumber,
    invoiceId,
    invoiceNumber,
    invoiceAmount,
    invoiceDate,
    pdfBase64,
  } = item;
  const fDate = formatDate(invoiceDate, isRtl);
  const handleDownload = async () => {
    if (!pdfBase64 || pdfBase64 === '') {
      dispatch(showToast(t.DownloadFailed));

      return;
    }

    await downloadInvoice(pdfBase64, invoiceNumber);
  };

  return (
    <div className="p-4 max-w-md bg-white rounded-lg shadow-md flex items-center justify-between">
      {/* Left section with icon and details */}
      <div className="flex items-center space-x-4">
        <img
          className="iconImg"
          src={`${process.env.PUBLIC_URL}/images/icons/medication.png`}
          alt="icon"
        />
        <div>
          <span className="titleDoc">{invoiceNumber}</span>
          <div className="mvalue flex items-center gap-1 text-gray-500 text-sm mt-1">
            <FiCalendar />
            <span>{`${fDate?.monthDate} ${fDate?.monthName} ${fDate?.year}`}</span>
          </div>
        </div>
      </div>

      {/* Right section with amount and download icon */}
      <div className="flex items-center space-x-4">
        <div className="text-right">
          <span className="text-xm text-gray-500 mb-1">{t.SAR}</span>
          <h5 className="text-md font-bold primaryFontColor">
            {invoiceAmount}
          </h5>
        </div>
        <div className="downloadButtonIcon" onClick={handleDownload}>
          <FiDownload />
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
