import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './collapsibleWrapper.css';

const CollapsibleWrapper = ({ title, expand, children }) => {
  const isRtl = false;
  const [isExpanded, setIsExpanded] = useState(expand);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="w-full">
      {/* Header */}
      <div
        onClick={toggleExpand}
        className={`header flex justify-between items-center px-4 py-2 cursor-pointer ${
          !isExpanded ? ' rounded-lg shadow-sm bg-white' : ''
        }`}
      >
        <span className="title font-medium">{title}</span>
        <span
          className={`title transform transition-transform duration-300 ${
            isExpanded ? 'rotate-180' : 'rotate-0'
          } ${isRtl ? '-rotate-180' : ''}`}
        >
          <FaChevronDown />
        </span>
      </div>

      {/* Collapsible Content */}
      <div
        className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
          isExpanded ? 'h-full' : 'max-h-0'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleWrapper;
