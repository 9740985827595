import React from 'react';
// import { useLoaderData } from 'react-router-dom';
// import ThreeDocsColumn from '../components/Dashboard/ThreeDocsColumn';
// import SingleRecentReport from '../components/Dashboard/SingleRecentReport';
// import RightSidebar from '../components/Dashboard/RightSidebar';
import ManageAppointments from '../components/Dashboard/ManageAppointments';
import VitalReports from '../components/Dashboard/VitalReports';
import Assesments from '../components/Dashboard/Assesments';
import CompletedAppointment from '../components/CrNew/Completed-Appointment';
import '../components/Dashboard/Dashboard.css';
import { useSelector } from 'react-redux';
import VitalChartModal from '../components/CrNew/Completed-Appointment/vitals/VitalChartModal';

const Dashboard = (props) => {
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );

  return (
    <>
      <div className="grid max-[767px]:grid-cols-1 max-[1199px]:grid-cols-2 gap-6 grid-cols-3">
        <ManageAppointments />
        {selectedManageAppointment && (
          <CompletedAppointment appointment={selectedManageAppointment} />
        )}
        <VitalReports />
        <Assesments />
      </div>
      <VitalChartModal />
    </>
  );
};

export default Dashboard;
