import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';
import { VITALS_REPORT_SINGLE } from '../Vitals';

const getUniqueVitalsById = (vitalsList) => {
  const seenIds = new Set();
  return vitalsList.filter((vital) => {
    if (seenIds.has(vital.id)) {
      return false; // Skip duplicates
    }
    seenIds.add(vital.id);
    return true; // Keep unique items
  });
};

const fetchVitalByEpisode = async (pageNo = 1, episodeId) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const selectedHospInfo = JSON.parse(
      localStorage.getItem('selectedHopitalInfo')
    );
    const hosp = selectedHospInfo?.code ?? 'IMC';
    const hospitalId = selectedHospInfo?.id ?? '1';
// const enpoint= 'api/health/patient/vitals?patientId=684297&page=1&episodeId=24273210'
    const endpoint = `api/health/patient/vitals?patientId=${userInfo.patientId}&page=${pageNo}&episodeId=${episodeId}&hosp=${hosp}`;

    const data = await baseFetch(endpoint);

    return data;
  } catch (error) {
    console.error('Viltal reports by episodeId HTTP error! status:');
    throw error;
  }
};

function useFetchVitalsByEpisode() {
  const [data, setData] = useState([]);
  // const [data, setData] = useState(VITALS_REPORT_SINGLE.vital_list);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );

  const fetchData = async () => {
    if (loading || !hasMore) return; // Prevent fetching if already loading or no more data
    if (page < 2) {
      setLoading(true);
    }

    setError(null);

    try {
      const apiData = await fetchVitalByEpisode(
        page,
        selectedManageAppointment?.id
      ); 
      console.log('yy apiData', apiData)
      const vitalList = apiData?.vital_list || [];

      if (vitalList.length === 0 || apiData.total <= page) {
        setHasMore(false); // No more data to fetch
      } else {
        // setData((prevData) => [...prevData, ...vitalList]);
        setData((prevData) => {
          let uniqueDataList = getUniqueVitalsById([...prevData, ...vitalList])
          return uniqueDataList
        });
        
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (selectedManageAppointment?.id) {
  //     fetchData();
  //   }
  // }, [hospitalId,  selectedManageAppointment, page]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      fetchData();
    }
  }, [page]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      setLoading(true)
      setData([])
      setPage(1)
    }
  }, [hospitalId,selectedManageAppointment]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchVitalsByEpisode;
