import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';
import { languageApiUpdate } from '../../utilFunctions/languageChangeApi';

const LanguageSwitch = () => {
  const { language, isRtl } = useSelector(selectLanguage);
  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    dispatch(languageActions.changeLanguage(newLanguage));
    languageApiUpdate(newLanguage);
  };

  const arabicStyle = {
    display: 'block',
    marginTop: '-4px',
    fontFamily: 'TheSans, sans-serif',
  };

  return (
    <div dir="ltr">
      <label
        className={isRtl ? 'checked customToggle' : 'customToggle'}
        htmlFor="themeTgle"
      >
        <input
          id="themeTgle"
          type="checkbox"
          checked={isRtl ? true : false}
          onChange={handleCheckboxChange}
          className="sr-only"
        />
        <span className="text">
          {isRtl ? 'EN' : <span style={arabicStyle}>ع</span>}
        </span>
        <span className="circle"></span>
      </label>
    </div>
  );
};

export default LanguageSwitch;
