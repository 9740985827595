export const EN = {
  //----dashboard----
  dashboard: 'Dashboard',
  //----Appointments----
  Appointments: 'Appointments',
  All: 'All',
  Completed: 'Completed',
  completed: 'Completed',
  declined: 'declined',
  inprogress: 'InProgress',
  No_Show: 'No Show',
  Cancelled: 'Cancelled',
  //----familyprofile----
  Family_Profile: 'Family Profile',
  Add_A_Guardian: 'Add Guardian',
  Guardian: 'Guardian',
  MR_Number: 'MR Number',
  Update_A_Guardian: 'Update Guardian',
  Duration_Of_Guardian: 'Duration Of Guardian',
  Unlimited: 'Unlimited',
  Enter_MR_Number: 'Enter MR number',
  Enter_Number_of_Days: 'Enter number of days',
  Number_of_Days: 'Number of days',
  Update: 'Update',
  Add: 'Add',
  My_Gurdians: 'My Gurdians',
  //----EditProfile----
  Edit_Profile: 'Edit Profile',
  Passport_No: 'National/Iqama ID',
  Enter_Passport_No: 'National/Iqama ID',
  Email: 'Email',
  Enter_Email: 'email',
  Date_of_Birth: 'Date of birth',
  Address: 'Address',
  Address_1: 'Address in English',
  Address_2: 'Address in Arabic',
  Insurance: 'Insurance',
  Enter_Insurance: 'Enter insurance',
  Cancel: 'Cancel',
  Save: 'Save',
  Something_Wrong: 'Something went wrong. Try later',
  My_Dependents: 'My Dependents',
  No_Data: 'No Data',
  Relation: 'relation',
  //--------Reports------
  Reports: 'Reports',
  Sno: 'S No.',
  Date: 'Date',
  Year: 'Year',
  Order_Id: 'Order ID',
  Ordered_By: 'Ordered By',
  Time: 'Time',
  Download: 'Download',
  SmartReport: 'Smart Report',
  Action: 'Action',
  LabReports: 'Lab Reports',
  RadiologyReport: 'Radiology Report',
  OperativeReport: 'Operative Report',
  CardiologyReport: 'Cardiology Report',
  DischargeReports: 'Discharge Reports',
  SickReport: 'Sick Report',
  RequestReports: 'Request Reports',
  NoDataFound: 'No Data Found',
  Value: 'Value',
  ReferenceRange: 'Reference Range',
  Intrepatation: 'Interpretation',
  CollectionDateTime: 'Collection Date & Time',
  Test: 'Test',
  Result: 'Result',
  Flag: 'Flag',
  Units: 'Units',
  RefRange: 'Ref. Range',

  Medication: 'Medication',
  AssessmentReport: 'Assessment Report',
  HomeHealthCare: 'Home Health Care',
  VitalReport: 'Vital Report',
  BookAppointment: 'Book Appointment',
  YourScore: 'Your Score',
  Score: 'Score',
  Assessments: 'Assessments',
  RecentReports: 'Recent Reports',
  SubmittedOn: 'Submitted on',
  at: 'at',
  //----------
  Request_A_Report: 'Request A Report',
  EmailAddress: 'Email Address',
  EnterYourEmailAddress: 'Enter Your Email Address',
  CellPhoneNumber: 'Cell Phone Number',
  ChooseAReportType: 'Choose A Report Type',
  SelectYourReportType: 'Select Your Report Type',
  Type: 'Type',
  SelectAVisit: 'Select A Visit',
  Visit: 'Visit',
  ReceiveByEmail: 'I would like to receive it by email',
  ReceiveByPersonal: 'I would like to receive it in person',
  Message: 'Message',
  TypeMessage: 'Type Your Message here.',
  Request: 'Request',
  SignOut: 'SignOut',
  Logout: 'Logout',
  BookAppointments: 'Book Appointments',
  VitalSigns: 'Vital Signs',
  VirtualTour: 'Virtual Tour',
  IMCWellness: 'IMC Wellness',
  ManageAppointments: 'Manage Appointments',
  LoginId: 'Login',
  Or: 'Or',
  NationalIqamaPassport: 'National/Iqama ID',
  errorMsg: 'Please enter either your MR Number or any ID number to login',
  errorInvalidMRN: 'Invalid MRN',
  errorNID: 'Invalid National ID',
  RememberMe: 'Remember Me',
  CopyWrite:
    'Copyright © 2025 International Medical Center, All Rights Reserved',
    CopyWrite1:
    'Copyright © ',
    CopyWrite2:
    'International Medical Center, All Rights Reserved',
  SignIn: 'Sign In',
  NewToIMC: 'New to IMC ? ',
  Register: 'Register',
  ResendCode: 'Resend Code',
  In: 'In',
  SwiftCallback: 'Swift Callback',
  SwiftCallbackMessage:
    'Team Home Health Care will call you back as soon as the request received',
  InstantHomeHealthcare: 'Instant Home Healthcare',
  InstantHomeHealthcareMsg:
    'Tab on to request any home healthcare service form listed',
  TBD: 'TBD',
  Name: 'Name',
  EnterYourFirstName: 'Enter Your First Name',
  BookService: ' Book Service',
  Times: 'Times',
  Description: 'Description',
  DeliveryNumber: 'Delivery Number',
  Delivery: 'Delivery',
  NoOfdays: 'No. of days',
  Quantity: 'Quantity',
  DeliveryDate: 'Delivery Date',
  Number: 'Number',
  HowtoUse: 'How to Use',
  Verified: 'Verified',
  NotVerified: 'Not Verified',
  New: 'New',
  History: 'History',

  NewRegistration: 'New Registration',
  FirstName: 'First Name',
  FirstNameisRequired: 'First name is required',
  FatherMiddleName: 'Father/Middle name',
  EnterYourFatherMiddleName: 'Enter your father/ fiddle name',
  MiddleNameRequired: 'middle name is required',
  FamilyLastName: 'Family/Last name',
  EnteryourFamilyLastName: 'Enter your family/last name',
  LastNameisRequired: 'Last name is required',
  MobileNumberRequired: 'Mobile number is required',
  IDType: 'ID Type',
  EnterIDType: 'Enter ID type',
  Select: 'Select',
  IqamaNo: 'Iqama no',
  NationalID: 'National ID',
  Passport: 'Passport',
  BirthNotification: 'Birth notification',
  IDTypeRequired: 'ID type is required',
  IDNumber: 'ID number',
  EnterNumberHere: 'Enter number here',
  IDNumberRequired: 'ID number is required',
  IDExpiryDate: 'ID expiry date',
  SelectExpirySDate: 'Select expiry date',
  ExpiryDateRequired: 'Expiry date is required',
  Gender: 'Gender',
  SelectGender: 'Select your gender',
  Male: 'Male',
  Female: 'Female',
  GenderRequired: 'Gender is required',
  DateofBirth: 'Date of Birth',
  SelectDateBirth: 'Select date of birth',
  DateBirthRequired: 'Date of birth is required',
  SignUp: 'Sign Up',
  Next: 'Next',
  Alreadyaccount: 'Already have a account ? ',

  Back: 'Back',
  OTPVerification: 'OTP Verification',
  WeHaveSendAnOTPOnYourNumber:
    'Please enter the 4-digit OTP sent to your registered mobile number.',
  DoYouWantToSwitchToDependent: 'Do You Want To Switch To Dependent ?',
  No: 'No',
  Yes: 'Yes',
  SwitchBack: 'Switch Back',
  Yourareviewing: 'Your are viewing',
  Verify: 'Verify',
  Timeline: 'Timeline',
  Nationality: 'Nationality',
  NationalityIsRequired: 'Nationality Is required',

  //BookingAppointments
  Symptoms: 'Symptoms',
  Doctor: 'Doctor',
  Searchby: 'Search by',
  SelectedDoctorInformation: 'Selected Doctor Information',
  DoctorsList: 'Doctors List',
  NoResultsfound: 'No Results found.',
  Hospital: 'Hospital',
  Clinic: 'Clinic',
  Physician: 'Physician',
  ConsultationType: 'Consultation Type',
  PatientPay: 'PatientPay',
  TotalAmount: 'Total Amount',
  PayLater: 'Pay Later',
  PayNow: 'Pay Now',
  ConfirmBooking: 'Confirm Booking',
  ProceedtoBooking: 'Proceed to Booking',
  TeleMedicineConsent: 'TeleMedicine Consent',
  optionsTitle:
    'While our doctors are able to treat the top issues you would go see a doctor about, there are conditions that we don’t treat. Please see an in-person doctor or hospital if you experience any of the following',
  NoteMsg11:
    'I certify that I am NOT experiencing any of the above health conditions/symptoms.',
  NoteMsg12:
    'I the patient/parent/guardian, hereby consent to the participation of care and treatment under IMC Telehealth.',
  NoteMsg00:
    'Our doctors are unable to write prescriptions for controlled substances. Please see a doctor in person if you require medication classified as a controlled substance.',
  NoteMsg2:
    'Note: IMC will not be liable in case information is not correctly provided by the patient.',
  //Doctor Card
  LanguagesSpoken: 'Languages Spoken',
  SelectService: 'Select Service',
  EarliestAppointment: 'Earliest Slot ',
  InPerson: 'In Person',
  Tele: 'Tele',
  Telemedicine: 'Telemedicine',
  Close: 'Close',
  DoctorName: 'Doctor name',
  ClinicName: 'Clinic name',
  Specialties: 'Specialties',
  Education: 'Education',
  Timing: 'Timing',
  UpcomingAppointments: 'Upcoming Appointments',
  Upcoming: 'Upcoming',
  ScanVisitNumber: 'Scan Visit Number',
  Reschedule: 'Reschedule',
  CancellationMsg: 'Are you sure, you want to cancel this appointment?',
  Confirm: 'Confirm',
  Confirmed: 'Confirmed',
  ConfirmCanellation: 'Confirm Canellation',
  ConfirmedMessage:
    'Your appointment booked successfully. You will get a reminder for this appointment prior 45 minutes of scheduled time.',
  ViewProfile: 'View Profile',

  TraumaticBrainOrSpinalCordInjury: 'Traumatic brain or spinal cord injury',
  ChestPainAndOrNumbness: 'Chest pain and/or numbness',
  VomitingOrCoughingBlood: 'Vomiting or coughing blood',
  Lacerations: 'Lacerations',
  LossOfConsciousness: 'Loss of consciousness',
  BrokenBones: 'Broken bones',
  SevereBurns: 'Severe burns',
  AcuteVisionLoss: 'Acute vision loss',
  ChestPain: 'Chest pain',
  AlteredMentalStatus: 'Altered mental status',
  BloodInStool: 'Blood in stool',
  HomicidalOrSuicidalThoughts: 'Homicidal or suicidal thoughts',
  DrugOverdose: 'Drug overdose',
  loginAgain: 'Login Again',
  sessionExpireMsg: 'Your session has been expired. Please login again.',
  ok: 'OK',
  kaustMsg:
    'This service is only for staff and employees of KAUST, if you are from outside the campus, you will need a permit to enter',
  'Invalid Credentials': 'Invalid Credentials',
  CouldNotloaddata: 'Could not load data',
  Idisagree: 'I disagree',
  Iagree: 'I agree',
  Payment: 'Payment',
  Paid: 'Paid',
  Pending: 'Pending',
  InsuranceMessage:
    'Insurance approval pending. Payment option available shortly.',
  InvalidOtpisEntered: 'Invalid OTP is entered',
  Error: 'Error',
  Confirmation: 'Confirmation',
  vitalCardWarning: 'You can only select a maximum of 4 vital cards.',
  Notifications: 'Notifications',
  Call: 'Call',

  locationMap: 'Location Map',
  contactUs: 'Contact Us',
  send: 'Send',
  CouldNotValidateEmail: 'Could not validate Email',
  secondOpinion: 'Second Opinion',
  Title: 'Title',
  secondOpinionAlertMsg:
    'Your information will be reviewed upon receipt. We will strive to provide you with a Mayo Clinic Second Opinion; however, a prior booking with IMC network physicians is required.',
  secondOpinionAlertMsg2: 'Have you already visited IMC physician?',
  SelectReason: 'Reason for Mayo Clinic Second Opinion',
  SelectEpisode: 'Select a visit',
  seenByIMCdoctor: 'Visited IMC Network Doctor',
  casesUrgency: 'Urgency Case',
  errorMsgCasesUrgency:
    'Mayo Clinic Second Opinion currently handles non-urgent cases.',
  secondOpinionSuccess:
    'Thanks for submitting your request, representative will be in touch to discuss your request.',
  requestSubmitted: 'Request Submitted',
  EmailNotVerifiedYet: 'Email not verified yet.',
  AllFieldsRequired: 'All * fields are required.',
  NoRecord: 'No record(s) found.',
  tourStep1:
    'Please ensure you have selected the correct location before proceeding.',
  noDocMsg: 'Doctor is not currently available',
  SelectRelation: 'Select Relation',
  NoVitalsRecord: 'No Vitals record(s) found.',
  Success: 'Success',
  SuccessMessage: 'Your payment is verified.',
  PaymentFailed: 'Payment Failed, please try again later.',
  PaymentDeclined: 'Payment declined, please try again later.',
  PleaseWait: 'Please wait',
  ProcessingMessage: 'processing your payment..',
  Messages: 'Messages',
  ChatPlaceholder: 'Type message...',
  AppointmentCancelled: 'Appointment Cancelled.',
  HhsSuccess: 'Service booked successfully.',
  HhsFailed: 'Error Service booking .',
  FormSubmitted: 'Form submitted successfully.',
  FormFailed: 'Error form submitting .',
  Note: 'Note',
  VerifyEmailMessage: 'Please verify your email.',
  NotValidEmail: 'Please enter valid email.',
  MessageFieldRequired: 'Message field required.',
  PicSuccessMessage: 'Profile picture updated.',
  PicErrorMessage: 'Error User profile updating',
  MapError: 'Error loading map. Try later',
  // cr27
  Invoices:'Invoices',
  DownloadSuccess:'Download available',
  DownloadFailed:'Download not available',
  SAR:'SAR',
  Medications:'Medications',
  MedicationNumber:'Medication Number',
  PrescriptionNumber:'Prescription Number',
  Howtouse:'How to use',
  Prescriptions:'Prescriptions',
  Vitals:'Vitals',
  Approved:'Approved',
  InsuranceTax:'Insurance Tax',
  PendingInsurance:'Pending insurance',
  Skip:'Skip',
  Previous:'Previous',
  Submit:'Submit',
  EpisodeId:'Episode ID',
  initialMsg:'Loading .... Please wait!',
  doctorRequestMsg:'Sending Request to doctor for joining',
  doctorLeftRoom:'Doctor no longer in call.',
  firstTimeJoin:'Thank you for joining. The doctor is currently consulting with other patients. You are next in line and will updated soon. Be ready with all the relevant information and thank you for your patience.',
  QueueMessageJoin:'Thank you for joining. The doctor is currently consulting with other patients. You are next in line and will be attended to after {count} patient(s). Be ready with all the relevant information and thank you for your patience.'
};
