import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TiTick } from "react-icons/ti";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import cancelAppointment from '../../../utilFunctions/cancelAppointment';
import { envVariables } from '../../../config';
import closeOpenedDropdown from '../../../utilFunctions/DropdownCloser';
import './style.css';
import { bookAppointmentActions, modalActions, searchbarActions } from '../../../store';
import { selectLanguage } from '../../../store/languageSlice';
import {
  updateSelectedAppontemnt,updateSurveyAppontemnt
} from '../../../store/appointmentSlice';
import CustomeModal from '../../Modal/CustomeModal';
import SingleAppointment from './SingleAppointment';
import { useNavigate } from 'react-router-dom';
import Loader from '../../UI/Loader';
import moment from 'moment';
import { removeBadge } from '../../../store/badgesSlice';

const UpcomingAppoitmentSlider = ({
  title,
  hideMenu,
  updateAppointmentsFunc,
  bookedAppointments,
  doctorsInfoList,
  appointmentConfirmationHandler,
  handleSearchDoctors,
  isInfo
}) => {
  const { assetsRoute } = envVariables;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, isRtl } = useSelector(selectLanguage);
  const hospId = useSelector((state) => state.hospitalInfo.hospId);
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);
  const [slotBookingId, setslotBookingId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showNoDoc, setShowNoDoc] = useState(false);

  const [apiMessage, setApiMessage] = useState({
    message: null,
    loading: false,
    error: false,
  });

  const upcomingAppointment = bookedAppointments;

  // const getDocImage = (docCode) => {
  //   const doctor = doctorsInfoList.find((doctor) => doctor.docCode === docCode);
  //   if (doctor) {
  //     return (
  //       <img
  //         src={`${assetsRoute}${hospCode}/${docCode}.jpg`}
  //         alt={doctor.docName}
  //       />
  //     );
  //   } else {
  //     return (
  //       <img
  //         src={`${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`}
  //         alt="Avatar"
  //       />
  //     );
  //   }
  // };

  const callBtnClickHandler = async (item, buttonType, servicesPrice) => {
    //item = clicked bookingappontment
    //buttonType = call or pay

    const { isDoctorActive, serviceType } = item;

    if (buttonType === 'pay') {
      const date = moment(item?.apptDateString);
      const formattedDate = date.format('YYYY-MM-DD');
      const formattedTime = date.format('h:mm A');

      const selectedDoc = {
        docName: item.careProviderDescription,
        docNameAr: item.arabicProviderDescription,
        specialityDescriptionAR: item.arabicSpecialityDescription,
        specialityDescription: item.specialityDescription,
        selectedSlotId: item.slotBookingId,
        selectedDate: formattedDate,
        selectedSlotTime:formattedTime,
        appointmentType:'Online',
      };

      appointmentConfirmationHandler(selectedDoc, servicesPrice);
      
    } else if (buttonType === 'call' && isDoctorActive) {
    // } else if (buttonType === 'call') {
      // alert('We are working on it');
      dispatch(updateSelectedAppontemnt(item));
      dispatch(updateSurveyAppontemnt(item));
      navigate('/video-call');
    } else if (!isDoctorActive) {
      setShowNoDoc(true);
    }
  };

  const cancelBtnClickHandler = async (id, hospId) => {
    setApiMessage({
      message: null,
      loading: false,
      error: false,
    })
    setslotBookingId(id);
    closeOpenedDropdown();
    setShowModal(true);
  };

  const reSheduleBtnClickHandler = async (appointment) => {
    closeOpenedDropdown();
    const { bookingStatus, docCode, specialityCode } = appointment;

    dispatch(updateSelectedAppontemnt(appointment));
    dispatch(searchbarActions.selectedSpecialityCode(specialityCode));
    dispatch(searchbarActions.selectedDoctorCode(docCode));
    // setTimeout(()=>{
    //     dispatch(modalActions.toggle("Reschedule"))
    // },200)
  };

  const noBtnClickHandler = async (reload) => {
    setShowModal(false);
    reload && (await updateAppointmentsFunc());
  };

  const yesBtnClickHandler = async () => {
    setApiMessage({ ...apiMessage, loading: true });
    const request = await cancelAppointment(slotBookingId, hospId);
    if (request) {
      setApiMessage({ message: t.AppointmentCancelled, loading: false });
      handleSearchDoctors && handleSearchDoctors('', {code:null,docCode:null})
      dispatch(removeBadge());
      dispatch(searchbarActions.selectedSpecialityCode(null));
      dispatch(bookAppointmentActions.selectedDocCode(null))
      dispatch(bookAppointmentActions.docAvailableDates(null));
      dispatch(bookAppointmentActions.updatePrimaryOrLastVisited(null))
    } else {
      setApiMessage({ message: t.Something_Wrong, loading: false });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: hideMenu ? 1: 2,
          slidesToScroll: hideMenu ? 1: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  };

  return (
    <>
      <div className="upComApt rounded-lg">
        <h2 className="text-14 font-bold">{title? title: t.UpcomingAppointments}</h2>
        {upcomingAppointment.length > 1 && (
          <Slider className="upcom-apnt-sldr" {...settings}>
            {upcomingAppointment.map((ua, index) => {
              // const docImg = getDocImage(ua.docCode);

              return (
                <SingleAppointment
                  key={'ua' + index}
                  item={ua}
                  index={index}
                  // docImg={docImg}
                  hideMenu={hideMenu}
                  onCancel={cancelBtnClickHandler}
                  onReshdule={reSheduleBtnClickHandler}
                  onCallClick={callBtnClickHandler}
                  isInfo={isInfo}
                />
              );
            })}
          </Slider>
        )}

        {upcomingAppointment.length === 1 && (
          <div className="upcom-apnt-sldr">
            {upcomingAppointment.map((ua, index) => {
              // const docImg = getDocImage(ua.docCode);

              return (
                <SingleAppointment
                  key={'ua' + index}
                  item={ua}
                  index={index}
                  hideMenu={hideMenu}
                  // docImg={docImg}
                  onCancel={cancelBtnClickHandler}
                  onReshdule={reSheduleBtnClickHandler}
                  onCallClick={callBtnClickHandler}
                  isInfo={isInfo}
                />
              );
            })}
          </div>
        )}

        {showModal && (
          <CustomeModal
            title={apiMessage.message ? t.Confirmed : t.ConfirmCanellation}
            isOpen={true}
            onCancel={() =>
              noBtnClickHandler(apiMessage.message ? true : false)
            }
            onConfirm={apiMessage.message ? null : yesBtnClickHandler}
            cancelText={apiMessage.message ? t.Close : t.No}
            confirmText={
              apiMessage.message ? null : apiMessage.loading ? (
                <Loader roundLoader size="small" />
              ) : (
                t.Yes
              )
            }
            icon={apiMessage.message ? <TiTick color='green'/> : null}
          >
            <p className="appntInfo">
              {apiMessage.message ?? t.CancellationMsg}
            </p>
          </CustomeModal>
        )}
        {showNoDoc && (
          <CustomeModal
            title={t.Error}
            isOpen={true}
            onCancel={() => setShowNoDoc(false)}
            cancelText={t.Close}
          >
            <p className="appntInfo">{t.noDocMsg}</p>
          </CustomeModal>
        )}
      </div>
    </>
  );
};

export default UpcomingAppoitmentSlider;
