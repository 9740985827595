import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';
import { mockFetchRequest } from '../VitalsReportList';
import { SURVEYS_QUESTIONS_RESPONSE } from './mock';

function useFetchSurvey() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  //   const { selectedManageAppointment } = useSelector(
  //     (state) => state.appointments
  //   );

  const fetchData = async () => {
    if (loading) return;

    setLoading(true);
    setError(null);

    try {
        const apiData = await baseFetch(`api/feedback/survey`, {
          headers: {
            appointmentType: 'Telemedicine',
          },
        });

      // const apiData = await mockFetchRequest(SURVEYS_QUESTIONS_RESPONSE);

      if (apiData?.surveyDetails && apiData?.surveyDetails.length > 0) {
        setData(apiData?.surveyDetails);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [hospitalId]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchSurvey;
