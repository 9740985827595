import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import DaysSlider from './DaysSlider';
import VitalChart from './VitalChart';
import { useSelector } from 'react-redux';
import moment from 'moment';

const formatVitalsData = (groupData, targetDate) => {
  const selectedDate = moment(targetDate).format('YYYY-MM-DD')
  // Filter by the target date
  const filteredData = groupData.filter(
    (vital) => vital.observation_date === selectedDate
  );

  // Initialize an array with 24 hours of data, default value is null
  const hourlyData = Array(24).fill(0);

  // Populate the hourly data
  filteredData.forEach((vital) => {
    const hour = parseInt(vital.observation_time.split(':')[0], 10);
    hourlyData[hour] = parseInt(vital.value, 10);
  });

  // Format into the series structure
  const series = [
    {
      name: filteredData[0]?.observation_item_description || 'Unknown',
      data: hourlyData,
    },
  ];

  return series;
};

const ModalChart = () => {
  const { selectedVital } = useSelector(
    (state) => state.appointments
  );
  
  const [days, setDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [graphData, setGraphData] = useState([]);

  const updateGraphData = (date) => {
    // Generate or fetch data dynamically based on the selected date
    // const series = [
    //   {
    //     name: 'Systolic',
    //     data: Array.from({ length: 24 }, () => Math.floor(Math.random() * 800)),
    //   },
    // ];
    // setGraphData(series);
    setGraphData(formatVitalsData(selectedVital?.group, date));
  };

  useEffect(() => {
    updateGraphData(selectedDate);
  }, [selectedDate]);

  return (
    <div>
      <Dropdown selectedDate={selectedDate} setSelectedDate={setSelectedDate} setDays={setDays} groupData={selectedVital?.group} />
      <DaysSlider
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        days={days} 
      />
      <VitalChart
        xAxisTitle="Time (Hours)"
        yaxisTitle={`${selectedVital?.name} ${selectedVital?.uom}`}
        data={graphData}
      />
    </div>
  );
};

export default ModalChart;
