import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';
import store from '../../store';
import { showToast, showToastNoAutoCLose } from '../../store/toastSlice';
import { getSelectLanguage } from '../getCurrentSelectLanguage';
import { savePDF } from '../reports/reports';

const DownloadMsg = 'Please wait.... checking if download is available.';
const ErrorMsg = 'Download is unavailable.';
const SuccessMsg = 'Downloading ....';
const DownloadMsgAr =
  'يرجى الانتظار.... جاري التحقق مما إذا كان التقرير متاحًا';
const ErrorMsgAr = 'التقرير غير متاح';
const SuccessMsgAr = 'جارٍ التنزيل ....';

const getIsRtl = () => {
  const lang = getSelectLanguage();
  if (lang == 'ar') {
    return true;
  }

  return false;
};

const invoicesLoader = async (episodeId) => {
  try {
    let userData = JSON.parse(localStorage.getItem('userInfo'));
    const hospital = JSON.parse(localStorage.selectedHopitalInfo);

    // const dateTo = moment().format("YYYY-MM-DD");
    // const dateFrom = moment().subtract(1, 'year').format("YYYY-MM-DD");
    const dateTo = '2024-11-30';
    const dateFrom = '2024-01-01';

    // const endpoint = `api/reports/tax-invoice?patientId=22&dateFrom=2024-01-01&dateTo=2024-11-30&episodeNumber&hospitalCode&attachTaxInvoicePdf=true`;
    const endpoint = `api/reports/tax-invoice?patientId=${userData?.patientId}&dateFrom=${dateFrom}&dateTo=${dateTo}&episodeNumber=${episodeId}&hospitalCode=${hospital.code}&attachTaxInvoicePdf=true`;

    const data = await baseFetch(endpoint);

    return data;
  } catch (error) {
    console.error('Error on Medication', error);
    throw error;
  }
};

export const downloadInvoice = async (pdfBaseString, invoiceNumber) => {
  try {
    store.dispatch(
      showToastNoAutoCLose(getIsRtl() ? DownloadMsgAr : DownloadMsg)
    );
    await savePDF(
      pdfBaseString,
      `invoice-${invoiceNumber}`,
      getIsRtl() ? SuccessMsgAr : SuccessMsg
    );
  } catch (error) {
    store.dispatch(showToast(getIsRtl() ? ErrorMsgAr : ErrorMsg));
    console.error(`Error fetching  invoice-${invoiceNumber}:`, error);
    return null;
  }
};

function useFetchInvoicesByEpisode() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  const { selectedManageAppointment } = useSelector(
    (state) => state.appointments
  );

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    setError(null);

    try {
      const apiResponse = await invoicesLoader(selectedManageAppointment?.id); // Include hospitalId if needed in the API call
      const listData = apiResponse?.invoice_list || [];

      if (listData.length === 0) {
        setHasMore(false); // No more data to fetch
      } else {
        // setData((prevData) => [...prevData, ...listData]);
        setData(listData);
        // setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      setError(error);
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (selectedManageAppointment?.id) {
  //     fetchData();
  //   }
  // }, [hospitalId, page, selectedManageAppointment]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      fetchData();
    }
  }, [page]);

  useEffect(() => {
    if (selectedManageAppointment?.id) {
      setLoading(true)
      setData([])
      setPage(1)
    }
  }, [hospitalId,selectedManageAppointment]);

  return { data, loading, error, refetch: fetchData };
}

export default useFetchInvoicesByEpisode;
