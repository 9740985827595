import { useState, useEffect } from "react";
import getServicePrice from "./bookAppointment/getServicePrice";

const useServicesPrice = ({ enableFetch, slotBookingId, hospId }) => {
  const [servicesPrice, setServicesPrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchServicesPrice = async () => {
    try {
      setLoading(true);
      const appointmentResponse = await getServicePrice(slotBookingId, hospId);
      setServicesPrice(appointmentResponse);
    } catch (error) {
      console.error("Error fetching service price:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enableFetch) {
      fetchServicesPrice();
    }
  }, [enableFetch, slotBookingId, hospId]);

  return { servicesPrice, loading };
};

export default useServicesPrice;
