import React, { useState } from 'react';
import moment from 'moment';
import {
  FaClock,
  FaBolt,
  FaExchangeAlt,
  FaLightbulb,
  FaCalendarAlt,
} from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';
import StatusItem from '../Status';
import { selectLanguage } from '../../../../store/languageSlice';
import { formatDate } from '../../../../translations/common';
import { timeFormat } from '../../../../utilFunctions/DateFormatter';
import './reports.css';
import { useSelector } from 'react-redux';
import { REPORT_TYPES } from '../../../../utilFunctions/completedAppointment/useFetchReportsByEpisode';
import {
  downloadCardiologyReport,
  downloadDischargeReport,
  downloadOperativeReport,
  downloadRadiologyReport,
  downloadReport,
  downloadSickReport,
} from '../../../../utilFunctions/reports/reports';

const ReportItem = ({ report }) => {
  const {
    reportType,
    pdfDownloadId,
    collectedTime,
    collectedDate,
    doctor,
    result,
  } = report;
  const { t, isRtl } = useSelector(selectLanguage);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const handleDownload = async () => {
    if (!pdfDownloadId || pdfDownloadId === '') return;

    if (reportType === REPORT_TYPES.LAB) {
      await downloadReport(pdfDownloadId, 0);
    }
    if (reportType === REPORT_TYPES.CARD) {
      await downloadCardiologyReport(pdfDownloadId);
    }
    if (reportType === REPORT_TYPES.DISCHARGE) {
      await downloadDischargeReport(pdfDownloadId, reportType);
    }
    if (reportType === REPORT_TYPES.OPER) {
      await downloadOperativeReport(pdfDownloadId);
    }
    if (reportType === REPORT_TYPES.RADI) {
      await downloadRadiologyReport(pdfDownloadId);
    }
    if (reportType === REPORT_TYPES.SICK) {
      await downloadSickReport(pdfDownloadId);
    }
  };

  const reportsType = [
    {
      name: t.LabReports,
      type: REPORT_TYPES.LAB,
      icon: 'ic_lab_report.svg',
      iconHov: 'ic_lab_report_hov.svg',
    },
    {
      name: t.RadiologyReport,
      type: REPORT_TYPES.RADI,
      icon: 'ic_radiology_report.svg',
      iconHov: 'ic_radiology_report_hov.svg',
    },
    {
      name: t.OperativeReport,
      type: REPORT_TYPES.OPER,
      icon: 'ic_operative_report.svg',
      iconHov: 'ic_operative_report_hov.svg',
    },
    {
      name: t.CardiologyReport,
      type: REPORT_TYPES.CARD,
      icon: 'ic_cardiology_report.svg',
      iconHov: 'ic_cardiology_report_hov.svg',
    },
    {
      name: t.DischargeReports,
      type: REPORT_TYPES.DISCHARGE,
      icon: 'ic_discharge_report.svg',
      iconHov: 'ic_discharge_report_hov.svg',
    },
    {
      name: t.SickReport,
      type: REPORT_TYPES.SICK,
      icon: 'ic_sick_report.svg',
      iconHov: 'ic_sick_report_hov.svg',
    },
  ];

  const reportTypeDetails =
    reportsType.find((type) => type.type === reportType) || null;
  const fDate = formatDate(collectedDate, isRtl);
  const time = moment(collectedTime, 'HH:mm').format('hh:mm A');
  const dateTime = `${collectedDate} ${timeFormat(collectedTime)}`;

  return (
    <div className="mresContainer w-full gap-4">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex gap-3">
          <div
            className="flex flex-col items-center justify-center gap-1"
            style={{ minWidth: '50px' }}
          >
            <span
              className="leading-4"
              style={{ fontWeight: '500', fontSize: '14px' }}
            >{`${fDate?.monthNameShort}`}</span>
            <span
              className=" text-primary leading-4"
              style={{ fontWeight: '700', fontSize: '24px' }}
            >
              {`${fDate?.monthDate}`}
            </span>
            <span
              className="leading-4"
              style={{ fontWeight: '500', fontSize: '14px' }}
            >{`${fDate?.year}`}</span>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="statusContainer flex flex-row gap-2 px-1">
                <span className="statusIcon">
                  <img
                    alt={reportTypeDetails?.name}
                    className="statusIcon"
                    style={{ height: '14px' }}
                    src={`${process.env.PUBLIC_URL}/images/icons/${reportTypeDetails?.icon}`}
                  />
                </span>
                <span className="statusTitle">{reportTypeDetails?.name}</span>
              </div>
              <StatusItem />
            </div>

            <span className="titleDoc">{doctor}</span>
            <div className="mvalue flex items-center gap-1 text-gray-500 text-sm mt-1">
              <FaClock />
              <span>{time}</span>
            </div>
          </div>
        </div>
        <div className="downloadButtonIcon" onClick={handleDownload}>
          <FiDownload onClick={toggleExpanded} />
        </div>
      </div>
      {result && (
        <div className="w-full flex justify-end" onClick={toggleExpanded}>
          <span
            className={`title transform transition-transform duration-300 ${
              expanded ? 'rotate-180' : 'rotate-0'
            } ${isRtl ? '-rotate-180' : ''}`}
          >
            <FaChevronDown />
          </span>
        </div>
      )}
      {expanded && result && (
        <div
          className={`w-full p-4 rounded-3xl text-gray-700 `}
          style={{ background: '#F0F0F0' }}
        >
          <div className="flex items-center gap-2 mb-2">
            <FaBolt className="rCardIcon" />
            <span className="rCardLable">{t.Value}:</span>
            <span className="rCardValue ml-auto">{`${result?.value}`}</span>
          </div>
          <div className="flex items-center gap-2 mb-2">
            <FaExchangeAlt className="rCardIcon" />
            <span className="rCardLable">{t.ReferenceRange}:</span>
            <span className="rCardValue ml-auto">{`${result?.range}`}</span>
          </div>
          <div className="flex items-center gap-2 mb-2">
            <FaLightbulb className="rCardIcon" />
            <span className="rCardLable">{t.Intrepatation}:</span>
            <span className="rCardValue ml-auto">{`${result?.interpretation}`}</span>
          </div>
          <div className="flex items-center gap-2 mb-2">
            <FaCalendarAlt className="rCardIcon" />
            <span className="rCardLable">{t.CollectionDateTime}:</span>
            <span className="rCardValue ml-auto">{`${dateTime}`}</span>
          </div>
          <div
            className="mt-3 text-gray-600 text-sm px-2 py-1 rounded-full flex items-center gap-1"
            style={{ background: '#F5F5F5' }}
          >
            <FaCalendarAlt className="rCardIcon" />
            <span className="rCardIcon">{`${result?.description}`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportItem;
