import moment from 'moment';
import React, { useEffect, useState } from 'react';

const getMonthsAndYears = (groupData) => {
  const months = new Set();
  // const years = new Set();

  const yearsData = groupData.map((item) =>
    moment(item.observation_date).get('year')
  );
  const years = [...new Set(yearsData)];
  let formatedData = {};

  years.forEach((year) => {
    const months = new Set();
    groupData.forEach((item) => {
      const momentObj = moment(item.observation_date);
      if (momentObj.get('year') === year) {
        months.add(
          new Date(0, momentObj.get('Month')).toLocaleString('default', {
            month: 'long',
          })
        );
      }
    });

    formatedData[year] = Array.from(months);
  });

  return {
    years,
    yearsMonths: formatedData, // {"2024":["June","July","August"]}
  };
};

export const daysFormatter = (groupData, fullYear, monthIndex) => {
  const days = groupData
    .filter((item) => {
      const itemDate = new Date(item.observation_date);
      return (
        itemDate.getFullYear() == fullYear &&
        itemDate.getMonth() == monthIndex
      );
    })
    .map((item) => {
      const date = new Date(item.observation_date);
      const dayName = new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
      }).format(date);
      return { day: dayName, date: date.getDate() }; // Return day name and day number
    });

  return days;
};

  const MONTHS = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

const Dropdown = ({ selectedDate, setSelectedDate, setDays, groupData }) => {
  const formatedData = getMonthsAndYears(groupData);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const handleMonthChange = (e) => {
    const monthName = e.target.value;
    const newDate = new Date(selectedDate);

    const monthIndex = MONTHS.indexOf(monthName)
      const fDays = daysFormatter(groupData,newDate.getFullYear(),monthIndex);
      const date = fDays[0]?.date || 1;
      
      
      newDate.setMonth(monthIndex);
      newDate.setDate(date);
      
      setSelectedDate(newDate);
      setDays(fDays)
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    
    const nowMonths = formatedData.yearsMonths[year]
      const monthIndex = MONTHS.indexOf(nowMonths[0])
      const fDays = daysFormatter(groupData,year,monthIndex);
      const date = fDays[0]?.date || 1;
      
      const newDate = new Date(selectedDate);
      newDate.setMonth(monthIndex);
      newDate.setFullYear(year);
      newDate.setDate(date);
      
      setSelectedDate(newDate);
      setMonths(nowMonths);
      setDays(fDays)
  };

  useEffect(()=>{
    if(groupData) {
      const nowYear = formatedData.years[0]
      const nowMonths = formatedData.yearsMonths[nowYear]
      const monthIndex = MONTHS.indexOf(nowMonths[0])
      const fDays = daysFormatter(groupData,nowYear,monthIndex);
      const date = fDays[0]?.date || 1;
      
      const newDate = new Date(selectedDate);
      newDate.setMonth(monthIndex);
      newDate.setFullYear(nowYear);
      newDate.setDate(date);

      setSelectedDate(newDate);
      setYears(formatedData.years);
      setMonths(nowMonths);
      setDays(fDays)
    }

  },[groupData])

  return (
    <div className="flex justify-end gap-2 mb-4 w-full">
      <select
        value={months[selectedDate.getMonth()]}
        onChange={handleMonthChange}
        className="border border-secondary px-4 py-2 rounded-lg"
      >
        {months.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>
      <select
        value={selectedDate.getFullYear()}
        onChange={handleYearChange}
        className="border border-secondary px-4 py-2 rounded-lg"
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
