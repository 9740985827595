import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Dropdown from '../../UI/Dropdown/Dropdown';
import { HiDotsVertical } from 'react-icons/hi';
import { envVariables } from '../../../config';
import closeOpenedDropdown from '../../../utilFunctions/DropdownCloser';
import { selectLanguage } from '../../../store/languageSlice';
import { formatDate, getTValue } from '../../../translations/common';
import './style.css';
import getServicePrice from '../../../utilFunctions/bookAppointment/getServicePrice';
import useServicesPrice from '../../../utilFunctions/useServicesPrice';

const SingleAppointment = ({
  item,
  index,
  docImg,
  onCancel,
  onReshdule,
  onCallClick,
  hideMenu,
  isInfo
}) => {
  const { assetsRoute } = envVariables;
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const hospId = useSelector((state) => state.hospitalInfo.hospId);
  const hospCode = useSelector((state) => state.hospitalInfo.hospCode);

  const {
    slotBookingId,
    apptDateString,
    isPaid,
    isDoctorActive,
    serviceType,
    slotAfterBufferTime,
    slotBeforeBufferTime,
    teleBooking,
  } = item;

  const [isWithinBuffer, setIsWithinBuffer] = useState(false);
  const { servicesPrice, loading } = useServicesPrice({
    enableFetch: !isPaid && serviceType === 'Online',
    slotBookingId,
    hospId,
  });

  const checkTimeBuffer = () => {
    const now = moment();
    const apptDate = moment(apptDateString);

    const bufferStart = apptDate
      .clone()
      .subtract(slotBeforeBufferTime, 'minutes');
    const bufferEnd = apptDate.clone().add(slotAfterBufferTime, 'minutes');

    setIsWithinBuffer(now.isBetween(bufferStart, bufferEnd, null, '[]'));
    // setIsWithinBuffer(true);
  };

  const cancelBtnClickHandler = async () => {
    closeOpenedDropdown();
    onCancel && onCancel(slotBookingId, hospId);
  };

  const reSheduleBtnClickHandler = async () => {
    closeOpenedDropdown();
    onReshdule && onReshdule(item);
  };

  const callBtnClickHandler = async (buttonType) => {
    onCallClick && onCallClick(item, buttonType, servicesPrice);
  };

  const checkBookingConditions = (booking) => {
    const serviceDescription = booking.serviceDescription ?? '';
    const containsFollowUp = serviceDescription
      .toLowerCase()
      .includes('follow up');

    // Check other conditions
    const isNotBookingStatusSix = booking.bookingStatus !== 6;
    const isNotPaid = !booking.isPaid;

    // Return the combined result
    return !containsFollowUp && isNotBookingStatusSix && isNotPaid;
  };

  const TopStatus = () => {
    if (teleBooking !== 1) return null;
    const showPayButton = !isPaid && serviceType === 'Online' && !isInfo;
    // const showCallButton = isPaid && serviceType === 'Online' 
    const showCallButton = serviceType === 'Online' 
    const insuranceApproved = servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)

    const showInsuranceText =  !isPaid && serviceType === 'Online' && !insuranceApproved;

    return (
      <div className="statusDiv flex gap-1">
        <div
          className="status"
          style={{
            '--status-color': isPaid
              ? 'var(--color-secondary)'
              : 'var(--color-tertiary)',
          }}
        >
          {/* {t.Payment} {isPaid ? t.Paid : t.Pending} */}
          {`${showInsuranceText ? t.PendingInsurance : `${t.Payment} ${isPaid ? t.Paid : t.Pending}`}`}
        </div>
        {showCallButton && (
          <div
            className={`status`}
            style={{
              '--status-color': isWithinBuffer
                ? 'var(--color-green)'
                : 'var(--color-warning)',
            }}
          >
            <button
              className={`day-timing ${
                !isWithinBuffer ? 'cursor-not-allowed' : ''
              }`}
              // disabled={!isWithinBuffer}
              onClick={() => callBtnClickHandler('call')}
            >
              {t.Call}
            </button>
          </div>
        )} 
        { showPayButton && insuranceApproved &&  (
          <div
            className={`status`}
            style={{
              '--status-color': 'var(--color-warning)',
            }}
          >
            <button
              className="day-timing"
              onClick={() => callBtnClickHandler('pay')}
            >
              {t.PayNow}
            </button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    // Initial check
    checkTimeBuffer();

    // Set interval to check every 60 minutes
    const interval = setInterval(checkTimeBuffer, 60 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div key={index}>
        <div className="singSld flex flex-wrap">
          <TopStatus />
          {/* {teleBooking === 1 && (<span className="status" style={{"--status-color": isPaid === t.Paid ? "var(--color-secondary)" : "var(--color-tertiary)"}}>{t.Payment} {isPaid ? t.Paid : t.Pending}</span>)} */}
          {!hideMenu && (
            <Dropdown>
              <button>
                <HiDotsVertical />
              </button>
              <div className="dd-menu !px-0">
                <ul>
                  <li>
                    <button onClick={cancelBtnClickHandler}>{t.Cancel}</button>
                  </li>
                  {/* <li><button>{t.ScanVisitNumber}</button></li> */}
                  {checkBookingConditions(item) && (
                    <li>
                      <button onClick={reSheduleBtnClickHandler}>
                        {t.Reschedule}
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </Dropdown>
          )}
          {/* <span className="paymentStatus"></span> */}
          <div dir="ltr" className="inline-flex flex-wrap gap-x-8 info">
            <div>
              <span className="day-timing">
                {formatDate(apptDateString, isRtl).weekName}
              </span>
              <h3>{new Date(apptDateString).getDate()}</h3>
              <span>{formatDate(apptDateString, isRtl).monthNameShort}</span>

              {/* <div className="leading-3 mt-2">
                <span className="day-timing">{t.Payment}</span>
                <span>{isPaid ? t.Paid : t.Pending}</span>
              </div> */}
            </div>
            <div>
              <span className="day-timing">{t.Timing}</span>
              <h3>{moment(apptDateString).format('hh:mm')}</h3>
              <span>{moment(apptDateString).format('A')}</span>

              {/* {serviceType === 'Online' && (
                <div className="leading-3 mt-2">
                  <button
                    className={`day-timing`}
                    // disabled={isWithinBuffer}
                    onClick={() => callBtnClickHandler('call')}
                  >
                    {t.Call}
                  </button>
                </div>
              )} */}
              {/* <div className="leading-3 mt-2">
                {isPaid && serviceType === 'Online' && (
                  <button
                    className={`day-timing ${
                      isWithinBuffer ? 'cursor-not-allowed' : ''
                    }`}
                    disabled={isWithinBuffer}
                    onClick={() => callBtnClickHandler('call')}
                  >
                    {t.Call}
                  </button>
                )} 
                {!isPaid && serviceType === 'Online' &&  (
                  <button
                    className="day-timing"
                    onClick={() => callBtnClickHandler('pay')}
                  >
                    {t.PayNow}
                  </button>
                )}
              </div> */}
            </div>
          </div>
          <div className="img">
            {/* {docImg} */}
            <img
              src={`${assetsRoute}${hospCode}/${item.docCode}.jpg`}
              onError={(e) => {
                if (
                  e.target.src !==
                  `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`
                ) {
                  e.target.src = `${process.env.PUBLIC_URL}/images/mdoc-placeholder.png`;
                }
              }}
              alt={item.docName}
            />
            <div>
              <h3>
                {getTValue(
                  item,
                  'arabicProviderDescription',
                  'careProviderDescription',
                  isRtl
                )}
              </h3>
              <p>
                {getTValue(
                  item,
                  'arabicServiceDescription',
                  'serviceDescription',
                  isRtl
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAppointment;
