import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    modalVisible: false,
    modalSessionVisible: false,
    modalType: '',
    switchPatient: false,
    response: false,
    alertModal: false,
    isMayoForm: false,
    showUserTour: false,
    modalSurveyVisible: false,
  },
  reducers: {
    validateEmail(state, action) {
      state.modalVisible = true;
      state.modalType = 'emailOtp';
      state.response = action.payload;
      state.isMayoForm=false;
    },
    toggle(state, action) {
      state.modalVisible = !state.modalVisible;
      state.modalType = action.payload;
    },
    toggleSessionExpire(state, action) {
      state.modalSessionVisible = action.payload;
      state.modalType = 'sessionExpire';
      state.modalVisible = false;
      state.alertModal = false;
    },
    setSwitchPatient(state, action) {
      state.switchPatient = action.payload;
    },
    closeModal(state, action) {
      state.modalType = '';
      state.modalVisible = false;
      state.alertModal = false;
      state.modalSessionVisible = false;
    },
    closeAllExceptErrorIfShoingModal(state, action) {
      state.modalType = '';
      state.modalVisible = false;
      state.alertModal = false;
    },
    openModal(state, action) {
      state.alertModal = true;
    },
    opendMayoform(state, action) {
      state.isMayoForm = true;
    },
    initiateUserTour(state, action) {
      state.showUserTour = true;
    },
  },
});
export const selectModal = (state) => state.modal;
export const { openModal, closeModal } = modalSlice;
export default modalSlice;
