import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { updateSelectVital } from '../../../../store/appointmentSlice';
import { getIconByCode } from '../../../../utilFunctions/Vitals';
import AreaChart from './AreaChart';

import './vitals.css';

const VitalItem = ({ index, vital , vitalsList}) => {
  const dispatch = useDispatch();
  const handleOnClickVital = () => {
    dispatch(updateSelectVital(vital));
  };

  return (
    <div className="vital">
      <div className="flex">
        <div className="flex gap-3">
          <img className="iconImg" src={getIconByCode(vital.code)} alt="icon" />
          <div className="flex flex-col gap-1">
            <span className="name">{vital.name}</span>
            <span className="value">{vital.value}</span>
            <span
              className="unit"
              dangerouslySetInnerHTML={{
                __html: vital?.uom?.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
              }}
            ></span>
          </div>
        </div>
      </div>
      <div
        className="flex relative"
        style={{ height: '150px', width: '150px' }}
      >
        <AreaChart areaGraphData={vital?.areaGraphData} />
        <div
          onClick={handleOnClickVital}
          className="downloadButtonIcon absolute cursor-pointer"
          style={{ zIndex: 9, bottom: '44px', right: '26px' }}
        >
          <FiChevronRight />
        </div>
      </div>
    </div>
  );
};

export default VitalItem;
