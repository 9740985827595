import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Video from 'twilio-video';
import {
  IoIosMic as MicOnIcon,
  IoIosMicOff as MicOffIcon,
  IoIosCall as CallIcon,
  IoIosChatbubbles as Chatbubbles,
} from 'react-icons/io';
import {
  IoVideocam as VideoCamIcon,
  IoVideocamOff as VideoCamOff,
} from 'react-icons/io5';
import {
  createRoom,
  generateChatAccessToken,
  joiningRequestToDoc,
} from '../../utilFunctions/videoChat';
import Participant from './Participant';
import { selectAppointments } from '../../store/appointmentSlice';
import Chat from './Chat';
import Loader from '../UI/Loader';
import './chat.css';
import useBreakpoint from '../../utilFunctions/useBreakpoint';
import { modalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';

const VideoChat = () => {
  const dispatch = useDispatch();
  const {t} = useSelector(selectLanguage)
  const [showChat, setShowChat] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  // const  isMobile  = true
  const { selectedAppontemnt } = useSelector(selectAppointments);
  const { userFullName } = useSelector((state) => state.authentication);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [infoMessage, setInfoMessage] = useState(t.initialMsg);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatAccessToken, setChatAccessToken] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const toggleChat = () => {
    setShowChat(!showChat);
    setUnreadCount(0);
  };

  const handleChatNotifications = () => {
    if (!showChat) {
      setUnreadCount(unreadCount + 1);
    }
  };

  const handleFetchChatAccessToken = async()=>{
    const chatData = await generateChatAccessToken();
      // if (!chatData || !chatData.status || !chatData.accessToken)
      //   throw new Error(`Chat AccessToken Error ${chatData.status}`);

      setChatAccessToken(chatData?.accessToken);
  }


  const handleCreateVideoView = async () => {
    try {
      const participantConnected = (participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
        subscribeToParticipantTracks(participant);

        setTimeout(() => {
         !chatAccessToken && handleFetchChatAccessToken()
        }, 10000);
      };

      const participantDisconnected = (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
        setInfoMessage(t.doctorLeftRoom);
      };

      // Check for media device permissions
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      // Connect to Twilio Video room
      const connectedRoom = await Video.connect(token, {
        name: roomName,
      });
      setRoom(connectedRoom);
      connectedRoom.on('participantConnected', participantConnected);
      connectedRoom.on('participantDisconnected', participantDisconnected);
      connectedRoom.participants.forEach(participantConnected);
      setInfoMessage(t.doctorRequestMsg);
      await joiningRequestToDoc(selectedAppontemnt.docCode, roomName);
      setInfoMessage(t.firstTimeJoin);
    } catch (error) {
      setInfoMessage(error.mesage);
      console.error('Error connecting to Twilio Video:', error);
    }
  };

  const cleanupResources = () => {
    // Disconnect from video room
    if (room) {
      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          track.stop();
          track.detach();
        }
      });
      room.disconnect();
      setRoom(null);
    }
  };

  const handleLogout = useCallback((event) => {
    // cleanupResources();
    // setToken(null);
    navigate(-1);
    setTimeout(() => {
      dispatch(modalActions.toggle('FeedbackSurveyModal'));
    }, 1000);
  }, []);

  const subscribeToParticipantTracks = (participant) => {
    const trackSubscribed = (track) => {
      if (track.kind === 'audio') {
        const audioElement = track.attach();
        document.body.appendChild(audioElement);
      }
    };

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(publication.track);
      }
      publication.on('subscribed', trackSubscribed);
    });

    participant.on('trackSubscribed', trackSubscribed);
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
    if (room) {
      room.localParticipant.audioTracks.forEach((publication) => {
        if (isMuted) {
          publication.track.enable();
        } else {
          publication.track.disable();
        }
      });
    }
  };

  const handleVideoToggle = () => {
    setIsVideoOff(!isVideoOff);
    if (room) {
      room.localParticipant.videoTracks.forEach((publication) => {
        if (isVideoOff) {
          publication.track.enable();
        } else {
          publication.track.disable();
        }
      });
    }
  };

  const handleShareScreen = async () => {
    if (room?.localParticipant) {
      if (!isScreenSharing) {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia();
          const screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
            name: 'screen',
          });

          room.localParticipant.videoTracks.forEach((publication) => {
            if (
              publication.track.kind === 'video' &&
              publication.track.name !== 'screen'
            ) {
              publication.track.disable();
            }
          });

          room.localParticipant.publishTrack(screenTrack);

          screenTrack.on('stopped', () => {
            room.localParticipant.unpublishTrack(screenTrack);
            setIsScreenSharing(false);

            room.localParticipant.videoTracks.forEach((publication) => {
              if (
                publication.track.kind === 'video' &&
                publication.track.name !== 'screen'
              ) {
                publication.track.enable();
              }
            });
          });

          setIsScreenSharing(true);
        } catch (error) {
          console.error('Error sharing screen:', error);
        }
      } else {
        const screenTrack = Array.from(
          room.localParticipant.videoTracks.values()
        ).find((track) => track.track.name === 'screen');

        if (screenTrack) {
          screenTrack.track.stop();
          room.localParticipant.unpublishTrack(screenTrack.track);
          setIsScreenSharing(false);

          room.localParticipant.videoTracks.forEach((publication) => {
            if (
              publication.track.kind === 'video' &&
              publication.track.name !== 'screen'
            ) {
              publication.track.enable();
            }
          });
        }
      }
    }
  };

  const stopLocalTracks = (room) => {
    room.localParticipant.tracks.forEach((publication) => {
      const track = publication.track;
      if (track.kind === 'audio' || track.kind === 'video') {
        track.stop();
      }
      room.localParticipant.unpublishTrack(track);
    });
  };

  const createRoomAndAccessToken = async () => {
    setLoading(true);
    const {
      docCode: doctorId,
      careProviderCode,
      slotBookingId,
      apptDateString,
    } = selectedAppontemnt;

    const roomResponse = await createRoom(
      doctorId,
      slotBookingId,
      apptDateString
    );


    if (roomResponse?.status) {
      const { room, accessToken } = roomResponse.data;
      accessToken && setToken(accessToken);
      if (room) {
        setRoomName(room.uniqueName);
        setToken(accessToken);
        // setRoom(room);
      }

      // fetch chat token if chat room exist
      await handleFetchChatAccessToken();
    }

    setLoading(false);
  };

  useEffect(() => {
    if (selectedAppontemnt && !token && !loading) {
      createRoomAndAccessToken();
    }
  }, []);

  useEffect(() => {
    if (token && !room) {
      handleCreateVideoView();
    }

    return () => {
      if (room) {
        stopLocalTracks(room);
        room.disconnect();
      }
    };
  }, [token, room]);

  // if (loading) return <Loader roundLoader={true} />;

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));


  return (
    <div
      style={{ height: '88vh', width: '100%' }}
      className="bg-white flex flex-col"
    >
      {/* Main content area */}
      <div className="flex flex-grow overflow-hidden">
        {/* Video Grid */}
        <div
          className={`flex-grow ${
            showChat ? 'md:w-2/3' : 'w-full'
          } bg-gray-900 ${isMobile? 'flex' : 'grid'} grid-cols-1 md:grid-cols-2 gap-2 p-4`}
        >
          {/* Placeholder for video streams */}
          <div className={`bg-black flex items-center justify-center rounded-lg ${isMobile ? 'mobile-local':''}`}>
            {room && (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
                isScreenSharing={isScreenSharing}
              />
            )}
          </div>
          <div className={`bg-black flex items-center justify-center rounded-lg ${isMobile ? 'mobile-remote':''}`}>
          {remoteParticipants.length === 0 ? (
              <h2 className="text-white mx-4">{infoMessage}</h2>
               ) : (
                    remoteParticipants
          )}
            {/* {remoteParticipants} */}
          </div>
          {/* Add more video streams as needed */}
        </div>

        {/* Chat Sidebar */}
       { chatAccessToken &&  <Chat
          toggleChatBox={toggleChat}
          roomName={roomName}
          userName={userFullName}
          chatAccessToken={chatAccessToken}
          showChat={showChat}
          handleNotificatinCount={handleChatNotifications}
        />}
      </div>

      {/* Control Bar */}
      <div className="bg-white p-4 flex justify-center gap-2">
        <button onClick={handleMute}>
          <i className="text-xl leading-none">
            {isMuted ? <MicOffIcon /> : <MicOnIcon />}
          </i>
        </button>
        <button onClick={handleVideoToggle}>
          <i className="text-xl leading-none">
            {isVideoOff ? <VideoCamOff /> : <VideoCamIcon />}
          </i>
        </button>
        <button onClick={handleLogout}>
          <i className="text-xl leading-none bg-red">
            <CallIcon color="red" />
          </i>
        </button>
        {/* <button onClick={handleShareScreen}>
          <i className="text-xl leading-none">
            {isScreenSharing ? <ScreenShareOff /> : <ScreenShare />}
          </i>
        </button> */}
        <button disabled={!chatAccessToken} onClick={toggleChat} className='relative'>
          <i className="text-xl leading-none">
            <Chatbubbles />
          </i>
          {/* {unreadCount > 0 && ( */}
          {!showChat && unreadCount > 0 && (
            <span
              style={{
                position: 'absolute',
                width:'16px',
                height:'16px',
                top: '-5px',
                right: '-10px',
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '50%',
                padding: '5px',
                fontSize: '9px',
                fontWeight: 'bold',
              }}
            >
              {/* {unreadCount} */}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default VideoChat;
