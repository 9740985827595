export const AR = {
  //----dashboard----
  dashboard: 'القائمة الرئيسية',
  //----المواعيد----
  Appointments: 'المواعيد',
  All: 'الكل',
  Completed: 'مكتمل',
  No_Show: 'مواعيد لم يتم حضورها',
  Cancelled: 'المواعيد الملغاه ',
  completed: 'مواعيد مكتملة بتاريخ ',
  inprogress: 'في تَقَدم',
  declined: 'مرفوض',
  //----familyprofile----
  Family_Profile: 'الملف العائلي',
  Add_A_Guardian: 'إضافة وصي',
  Guardian: 'ولي الأمر',
  MR_Number: 'رقم الملف الطبي',
  Update_A_Guardian: 'تحديث الوصي',
  Duration_Of_Guardian: 'مدة الوصاية',
  Unlimited: 'غير محدود',
  Enter_MR_Number: 'أدخل رقم الملف الطبي',
  Enter_Number_of_Days: 'أدخل عدد الأيام',
  Number_of_Days: 'عدد الأيام',
  Update: 'تحديث',
  Add: 'إضافة',
  My_Guardians: 'أوصيائي',
  //----EditProfile----
  Edit_Profile: 'تعديل الملف',
  Passport_No: 'رقم جواز السفر',
  Enter_Passport_No: 'أدخل رقم جواز السفر',
  Email: 'البريد الإلكتروني',
  Enter_Email: 'أدخل البريد الإلكتروني',
  Date_of_Birth: 'تاريخ الميلاد',
  Address: 'العنوان',
  Address_1: 'العنوان باللغة الإنجليزية',
  Address_2: 'العنوان باللغة العربية',
  Insurance: 'التأمين',
  Enter_Insurance: 'أدخل التأمين',
  Cancel: 'إلغاء',
  Save: 'حفظ',
  Something_Wrong: 'حدث خطأ ما أثناء جلب البيانات',
  My_Dependents: 'المعالين عليَّ',
  No_Data: 'لا توجد بيانات',
  Relation: 'العلاقة',
  //--------Reports------
  Reports: 'التقارير',
  Sno: '#',
  Date: 'تاريخ الاصدار',
  Year: 'سنة',
  Order_Id: 'رقم الطلب',
  Ordered_By: 'اسم الطبيب',
  Time: 'الوقت',
  Download: 'تحميل',
  SmartReport: 'التقرير الذكي',
  Action: 'فعل',
  LabReports: 'تقارير المختبر',
  RadiologyReport: 'تقرير الأشعة',
  OperativeReport: 'تقرير العملية',
  CardiologyReport: 'تقرير القلبية',
  DischargeReports: 'تقارير الخروج',
  SickReport: 'تقرير المرض',
  RequestReports: 'طلب تقرير',
  NoDataFound: 'البيانات غير متاحه',
  Value: 'القيمة',
  ReferenceRange: 'النطاق المرجعي',
  Intrepatation: 'التفسير',
  CollectionDateTime: 'تاريخ ووقت الجمع',
  Test: 'اختبار',
  Result: 'النتيجة',
  Flag: 'رمز',
  Units: 'وحدات',
  RefRange: 'نطاق المرجع',
  //----------
  Medication: 'الدواء',
  AssessmentReport: 'تقرير التقييم',
  HomeHealthCare: 'الرعاية الصحية المنزلية',
  VitalReport: 'تقرير الصحة الأساسية',
  BookAppointment: 'حجز موعد',
  YourScore: 'نتيجتك',
  Score: 'نتيجتك',
  Assessments: 'التقييمات',
  RecentReports: 'التقارير الأخيرة',
  SubmittedOn: 'تم التقديم في',
  at: 'في الساعة',
  //----------
  Request_A_Report: 'اطلب تقريرًا',
  EmailAddress: 'عنوان البريد الإلكتروني',
  EnterYourEmailAddress: 'أدخل عنوان بريدك الإلكتروني',
  CellPhoneNumber: 'رقم الهاتف المحمول',
  ChooseAReportType: 'اختر نوع التقرير',
  SelectYourReportType: 'اختر نوع التقرير الخاص بك',
  Type: 'نوع',
  SelectAVisit: 'اختر زيارة',
  Visit: 'زيارة',
  ReceiveByEmail: 'أود استلامه عبر البريد الإلكتروني',
  ReceiveByPersonal: 'أود استلامه شخصيًا',
  Message: 'رسالة',
  TypeMessage: 'اكتب رسالتك هنا.',
  Request: 'طلب',
  SignOut: 'تسجيل الخروج',
  Logout: 'تسجيل الخروج',
  BookAppointments: 'حجز المواعيد',
  VitalSigns: 'العلامات الحيوية',
  VirtualTour: 'جولة افتراضية',
  IMCWellness: 'صحة وعافية IMC',
  ManageAppointments: 'إدارة المواعيد',
  LoginId: 'تسجيل الدخول',
  Or: 'أو',
  NationalIqamaPassport: 'الرجاء ادخال رقم الهوية الوطنية',
  errorMsg: 'يرجى إدخال رقم الملف الطبي الخاص بك أو أي رقم هوية لتسجيل الدخول',
  CopyWrite: 'حقوق النشر محفوظة © 2025 المركز الطبي الدولي',
  CopyWrite1: 'حقوق النشر محفوظة ©',
  CopyWrite2: 'المركز الطبي الدولي',
  RememberMe: 'تذكرني',
  SignIn: 'تسجيل الدخول',
  NewToIMC: 'جديد في IMC ؟',
  Register: 'تسجيل',
  ResendCode: 'إعادة إرسال الرمز',
  In: 'في',
  SwiftCallback: 'استدعاء سريع',
  SwiftCallbackMessage:
    'سيقوم فريق الرعاية الصحية المنزلية بالاتصال بك في أقرب وقت ممكن بمجرد استلام الطلب',
  InstantHomeHealthcare: 'الرعاية الصحية المنزلية الفورية',
  InstantHomeHealthcareMsg: 'اضغط لطلب أي خدمة رعاية صحية منزلية مدرجة',
  TBD: 'تحديد في وقت لاحق',
  Name: 'الاسم',
  EnterYourFirstName: 'أدخل اسمك الأول',
  BookService: ' حجز الخدمة',
  Times: 'مرات',
  Description: 'الوصف',
  DeliveryNumber: 'رقم التسليم',
  Delivery: 'توصيل',
  NoOfdays: 'عدد الأيام',
  Quantity: 'الكمية',
  DeliveryDate: 'تاريخ التسليم',
  Number: 'الرقم',
  HowtoUse: 'كيفية الاستخدام',
  Verified: 'تم التحقق',
  NotVerified: 'لم يتم التحقق',
  New: 'جديد',
  History: 'السابقة',

  NewRegistration: 'تسجيل جديد',
  FirstName: 'الاسم الأول',
  FirstNameisRequired: 'الاسم الأول مطلوب',
  FatherMiddleName: 'اسم الأب/الاسم الأوسط',
  EnterYourFatherMiddleName: 'أدخل اسم والدك/الاسم الأوسط',
  MiddleNameRequired: 'الاسم الأوسط مطلوب',
  FamilyLastName: 'اسم العائلة/اللقب',
  EnteryourFamilyLastName: 'أدخل اسم العائلة/اللقب',
  LastNameisRequired: 'اللقب مطلوب',
  MobileNumberRequired: 'رقم الجوال مطلوب',
  IDType: 'نوع الهوية',
  EnterIDType: 'أدخل نوع الهوية',
  Select: 'اختر',
  IqamaNo: 'رقم الإقامة',
  NationalID: 'الهوية الوطنية',
  Passport: 'جواز السفر',
  BirthNotification: 'إشعار ميلاد',
  IDTypeRequired: 'نوع الهوية مطلوب',
  IDNumber: 'رقم الهوية',
  EnterNumberHere: 'أدخل الرقم هنا',
  IDNumberRequired: 'رقم الهوية مطلوب',
  IDExpiryDate: 'تاريخ انتهاء الهوية',
  SelectExpirySDate: 'اختر تاريخ الانتهاء',
  ExpiryDateRequired: 'تاريخ الانتهاء مطلوب',
  Gender: 'الجنس',
  SelectGender: 'اختر جنسك',
  Male: 'ذكر',
  Female: 'أنثى',
  GenderRequired: 'الجنس مطلوب',
  DateofBirth: 'تاريخ الميلاد',
  SelectDateBirth: 'اختر تاريخ الميلاد',
  DateBirthRequired: 'تاريخ الميلاد مطلوب',
  SignUp: 'تسجيل',
  Next: 'التالي',
  Alreadyaccount: ' لديك حساب بالفعل ؟',
  Back: 'رجوع',
  OTPVerification: 'التحقق  من هاتفك',
  WeHaveSendAnOTPOnYourNumber:
    'يرجى إدخال رمز التحقق المكون من 4 أرقام المرسل إلى رقم هاتفك المحمول المسجل',
  DoYouWantToSwitchToDependent: ' هل تريد التحويل إلى تابع ؟',
  No: 'لا',
  Yes: 'نعم',
  SwitchBack: 'العودة',
  Yourareviewing: 'أنت تشاهد',
  Verify: 'تحقق',
  Timeline: 'الجدول الزمني',
  Nationality: 'الجنسية',
  NationalityIsRequired: 'الجنسية مطلوبة',

  //BookingAppointments
  Symptoms: 'الأعراض',
  Doctor: 'طبيب',
  Searchby: 'البحث بواسطة',
  SelectedDoctorInformation: 'معلومات الطبيب المختار',
  DoctorsList: 'قائمة الأطباء',
  NoResultsfound: 'لم يتم العثور على نتائج.',
  Hospital: 'مستشفى',
  Clinic: 'عيادة',
  Physician: 'طبيب',
  ConsultationType: 'نوع الاستشارة',
  PatientPay: 'دفع المريض',
  TotalAmount: 'المبلغ الإجمالي',
  PayLater: 'الدفع لاحقًا',
  PayNow: 'ادفع الآن',
  ConfirmBooking: 'تأكيد الحجز',
  ProceedtoBooking: 'المتابعة للحجز',
  TeleMedicineConsent: 'موافقة المعاينة عن بعد',
  optionsTitle:
    'في حين أن أطبائنا قادرون على علاج القضايا الرئيسية التي ستذهب لرؤية الطبيب بشأنها، هناك حالات لا نعالجها. يرجى رؤية طبيب أو مستشفى شخصيًا إذا كنت تعاني من أي مما يلي',
  NoteMsg11:
    'أقر بأنني لا أعاني من أي من الحالات/الأعراض الصحية المذكورة أعلاه.',
  NoteMsg12:
    'أنا المريض/الوالد/الوصي، أوافق بموجب هذا على المشاركة في الرعاية والعلاج تحت مستشفي الطب الدولي – المعاينة عن بعد',
  NoteMsg00:
    'لا يستطيع أطباؤنا كتابة وصفات للأدوية الخاضعة للرقابة. يرجى رؤية طبيب شخصيًا إذا كنت بحاجة إلى دواء مصنف كدواء خاضع للرقابة.',
  NoteMsg2:
    'ملاحظة: لن يكون مستشفي الطب الدولي مسؤولاً في حالة عدم تقديم المعلومات بشكل صحيح من قبل المريض.',
  LanguagesSpoken: 'اللغات المتحدثة',
  SelectService: 'اختر الخدمة',
  EarliestAppointment: ' أقرب موعد',
  InPerson: 'شخصيًا',
  Tele: 'عن بعد',
  Telemedicine: 'الطب عن بعد',
  Close: 'إغلاق',
  DoctorName: 'اسم الطبيب',
  ClinicName: 'اسم العيادة',
  Specialties: 'التخصصات',
  Education: 'التعليم',
  Timing: 'التوقيت',
  UpcomingAppointments: 'المواعيد القادمة',
  Upcoming: 'القادمة',
  ScanVisitNumber: 'مسح رقم الزيارة',
  Reschedule: 'إعادة جدولة',
  CancellationMsg: 'هل أنت متأكد أنك تريد إلغاء هذا الموعد؟',
  Confirm: 'تأكيد',
  Confirmed: 'رسالة التأكيد',
  ConfirmCanellation: 'تأكيد الإلغاء',
  ConfirmedMessage:
    'تم حجز موعدك بنجاح. ستتلقى تذكيرًا بهذا الموعد قبل 45 دقيقة من الوقت المحدد',
  ViewProfile: 'عرض الصفحة الشخصية',

  TraumaticBrainOrSpinalCordInjury: 'إصابة دماغية أو إصابة في الحبل الشوكي',
  ChestPainAndOrNumbness: 'ألم في الصدر و/أو خدر',
  VomitingOrCoughingBlood: 'تقيؤ أو سعال مع دم',
  Lacerations: 'جروح قطعية',
  LossOfConsciousness: 'فقدان الوعي',
  BrokenBones: 'كسور في العظام',
  SevereBurns: 'حروق شديدة',
  AcuteVisionLoss: 'فقدان حاد للبصر',
  ChestPain: 'ألم في الصدر',
  AlteredMentalStatus: 'حالة عقلية متغيرة',
  BloodInStool: 'دم في البراز',
  HomicidalOrSuicidalThoughts: 'أفكار انتحارية أو إجرامية',
  DrugOverdose: 'جرعة زائدة من المخدرات',
  sessionExpireMsg: 'انتهت جلستك. يرجى تسجيل الدخول مرة أخرى',
  ok: 'موافق',
  loginAgain: 'تسجيل الدخول مرة أخرى',
  kaustMsg:
    'ملاحظة: هذه الخدمة مخصصة فقط لمنسوبي وموظفي جامعة الملك عبد الله للعلوم والتقنية. إذا كنت من خارج الحرم الجامعي، فستحتاج إلى تصريح لدخول',
  'Invalid Credentials': 'الرقم غير صحيح! يرجى المحاولة مرة أخرى',
  CouldNotloaddata: 'تعذر تحميل البيانات',
  Idisagree: 'غير موافق',
  Iagree: 'موافق',
  Payment: 'الدفع',
  Paid: 'مدفوع',
  Pending: 'قيد الانتظار',
  InsuranceMessage: 'موافقة التأمين معلقة. خيار الدفع متاح قريبًا',
  InvalidOtpisEntered:
    'رمز التحقق غير صحيح الرجاء ادخال كلمة المرور لمرة واحدة بشكل صحيح',
  Error: 'خطأ',
  Confirmation: 'تأكيد',
  vitalCardWarning:
    'تحذير بطاقة الحيوية: يمكنك اختيار 4 بطاقات حيوية كحد أقصى فقط',
  Notifications: 'إشعارات',
  Call: 'يتصل',
  locationMap: 'خريطة الموقع',
  contactUs: 'اتصل بنا',
  send: 'إرسال',
  CouldNotValidateEmail: 'لا يمكن التحقق من صحة البريد الإلكتروني',
  secondOpinion: 'رأي ثاني',
  Title: 'عنوان',
  secondOpinionAlertMsg:
    'سيتم مراجعة معلوماتك عند الاستلام. سنسعى جاهدين لتزويدك بالرأي الثاني من عيادة مايو؛ ومع ذلك، يلزم الحجز المسبق مع أطباء شبكة المركز الطبي الدولي',
  secondOpinionAlertMsg2: 'هل سبق لك زيارة طبيب المركز الطبي الدولي؟',
  SelectReason: 'سبب الرأي الثاني لمايو كلينك',
  SelectEpisode: 'اختر زيارة',
  seenByIMCdoctor: 'زار طبيب من داخل شبكة الطب الدولي',
  casesUrgency: 'حالة عاجلة',
  errorMsgCasesUrgency: 'تتعامل Mayo Clinic حاليا مع الحالات الغير حرجة',
  secondOpinionSuccess:
    'شكرًا على تقديم طلبك، سوف يقوم أحد ممثلي خدمة العملاء بتواصل معاك لمناقشة طلبك',
  requestSubmitted: 'طلب مقدم',
  EmailNotVerifiedYet: 'لم يتم التحقق من البريد الإلكتروني بعد',
  AllFieldsRequired: 'جميع الحقول التي تحمل علامة * مطلوبة',
  NoRecord: 'لم يتم العثور على أي سجل (سجلات)',
  tourStep1: 'يرجى التأكد من أنك قمت بتحديد الموقع الصحيح قبل المتابعة',
  noDocMsg: 'الطبيب غير متوفر حاليا',
  SelectRelation: 'حدد العلاقة',
  NoVitalsRecord: 'لم يتم العثور على سجلات حيوية',
  Success: 'نجاح',
  SuccessMessage: 'تم التحقق من الدفع الخاص بك.',
  PaymentFailed: 'فشل الدفع، يرجى المحاولة مرة أخرى لاحقًا.',
  PaymentDeclined: 'تم رفض الدفع، يرجى المحاولة مرة أخرى لاحقًا.',
  PleaseWait: 'يرجى الانتظار',
  ProcessingMessage: 'جارٍ معالجة الدفع الخاص بك..',
  Messages: 'رسائل',
  ChatPlaceholder: 'اكتب رسالة...',
  AppointmentCancelled: 'تم الغاء الموعد',
  HhsSuccess: 'تم حجز الخدمة بنجاح',
  HhsFailed: 'خطأ في حجز الخدمة',
  FormSubmitted: 'تم إرسال النموذج بنجاح',
  FormFailed: 'خطأ في إرسال النموذج',
  Note: 'ملاحظة',
  VerifyEmailMessage: 'يرجى التحقق من بريدك الإلكتروني',
  NotValidEmail: 'يرجى إدخال بريد إلكتروني صالح',
  MessageFieldRequired: 'حقل الرسالة مطلوب',
  PicSuccessMessage: 'تم تحديث صورة الملف الشخصي',
  PicErrorMessage: 'حدث خطأ أثناء تحديث ملف المستخدم',
  MapError: 'حدث خطأ أثناء تحميل الخريطة. حاول لاحقًا',
  // cr27
  Invoices: 'الفواتير',
  DownloadSuccess: 'التنزيل متاح',
  DownloadFailed: 'التنزيل غير متوفر',
  SAR: 'ريال',
  Medications: 'دواء',
  MedicationNumber: 'رقم الدواء',
  PrescriptionNumber: 'رقم الوصفة الطبية',
  Howtouse: 'كيفية الاستخدام',
  Prescriptions: 'وصفة طبية',
  Vitals: 'العناصر الحيوية',
  Approved: 'موافقة',
  InsuranceTax: 'ضريبة التأمين',
  PendingInsurance: 'في انتظار التأمين',
  Skip: 'يتخطى',
  Previous: 'سابق',
  Submit: 'يُقدِّم',
  EpisodeId: 'معرف الحالة',
};
