import { getSelectLanguage } from './utilFunctions/getCurrentSelectLanguage';
import { sessionsMiddleWare } from './utilFunctions/SessionExpire';

export const IS_PROD = true;
export const BASE_NAME_ROUTE = '/'; //...... /imcpp or /imcportal
// Below will be used in production builds
export const HOST = 'https://patientportal.imc.med.sa/';
export const API_ENDPOINT = 'imc-tc-wrapper-service-v8/';
export const CHAT_HOST = 'https://whealth.imc.med.sa:9443/imc_doctor/';

// Below will be used in testing envirnoment
export const HOST_TEST = 'https://intgtest01.imc.med.sa/';
export const API_ENDPOINT_TEST = 'imc-tc-wrapper-service-v7/';
export const CHAT_HOST_TEST = 'https://10.11.28.74:8080/imc_doctor/';

const apiServer = localStorage.getItem('apiServer');

export const envVariables =
  apiServer === 'test'
    ? {
        host: HOST_TEST,
        apiRoute: `${HOST_TEST}${API_ENDPOINT_TEST}`,
        assetsRoute: `${HOST}uploads/docImg/`,
        assetsRouteUser: `${HOST}/uploads/patImg/`,
        apiChatRoute: CHAT_HOST_TEST,
      }
    : {
        host: HOST,
        apiRoute: `${HOST}${API_ENDPOINT}`,
        assetsRoute: `${HOST}uploads/docImg/`,
        assetsRouteUser: `${HOST}uploads/patImg/`,
        apiChatRoute: CHAT_HOST,
      };

export const consoleLog = (tagOrData, data) => {
  if (IS_PROD) return;

  if (data === undefined) {
    console.log(tagOrData);
  } else {
    console.log(`[${tagOrData}]`, data);
  }
};

export const baseFetch = async (
  endpoint,
  { method = 'GET', body = null, headers = {} } = {}
) => {
  const { host, apiRoute } = envVariables;
  const url = `${apiRoute}${endpoint}`;
  // Logined User data
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const userMrn = userInfo?.mrn ?? '000348';
  const usePatientId = userInfo?.patientId ?? '22';
  const accessToken = userInfo?.accessToken ?? '';
  // Selected Hospital Data
  const selectedHospInfo = JSON.parse(
    localStorage.getItem('selectedHopitalInfo')
  );
  const hospCode = selectedHospInfo?.code ?? 'IMC';
  const hospitalId = selectedHospInfo?.id ?? '1';

  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept-Language': getSelectLanguage(),
    Accept: 'application/json, text/plain, */*',
    Authorization: `Bearer ${accessToken}`,
    HospitalID: hospitalId,
    Host: 'patientportal.imc.med.sa',
    MRN: userMrn,
    Patient_ID: usePatientId,
  };

  const config = {
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  };

  if (body && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, config);
    if (!response.ok) {
      if (response.status === 403) {
        sessionsMiddleWare();
        throw new Error(
          `HTTP error! status session expire : ${response.status}`
        );
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    throw error; // Optionally rethrow to handle it elsewhere
  }
};
