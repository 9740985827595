import React, { useState } from 'react';
import Loader from '../UI/Loader';
import useFetchSurvey from '../../utilFunctions/feedback/useFectchSurvey';
import useSubmitSurvey from '../../utilFunctions/feedback/useSubmitSurvey';
import './feedback.css';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../store';
import { selectLanguage } from '../../store/languageSlice';

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const { t, isRtl } = useSelector(selectLanguage);
  const { data: steps, loading, error } = useFetchSurvey();
  const {
    data: postResponse,
    loading: postLoading,
    error: postError,
    submitSurvey,
  } = useSubmitSurvey();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({}); // Store answers for each step

  const getEmojiByLabel = (label) => {
    if (['no', 'poor'].includes(label.toLowerCase())) {
      return `${process.env.PUBLIC_URL}/images/icons/poor.svg`;
    }
    if (['partially', 'fair'].includes(label.toLowerCase())) {
      return `${process.env.PUBLIC_URL}/images/icons/fair.svg`;
    }
    if (['yes', 'good'].includes(label.toLowerCase())) {
      return `${process.env.PUBLIC_URL}/images/icons/good.svg`;
    }
    if (['excellent'].includes(label.toLowerCase())) {
      return `${process.env.PUBLIC_URL}/images/icons/excellent.svg`;
    }
    return `${process.env.PUBLIC_URL}/images/icons/good.svg`;
  };

  const stepss = [
    {
      question: 'Did we address your health concern today?',
      options: [
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/poor.svg`,
          label: 'No',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/fair.svg`,
          label: 'Partially',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/good.svg`,
          label: 'Yes',
        },
      ],
    },
    {
      question: 'How would you rate your overall experience?',
      options: [
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/poor.svg`,
          label: 'Poor',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/fair.svg`,
          label: 'Fair',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/good.svg`,
          label: 'Good',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/excellent.svg`,
          label: 'Excellent',
        },
      ],
    },
    {
      question: 'Would you use our telemedicine service again?',
      options: [
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/poor.svg`,
          label: 'No',
        },
        {
          emoji: `${process.env.PUBLIC_URL}/images/icons/good.svg`,
          label: 'Yes',
        },
      ],
    },
  ];

  const isLastStep = currentStep === steps.length - 1;

  // Handle selecting an answer
  const handleSelectAnswer = (index) => {
    setAnswers((prev) => ({ ...prev, [currentStep]: index }));
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSkip = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmit = async () => {
    const surveyQuestions = steps.map((step, index) => ({
      question: step?.questionText,
      response: step?.answers[answers[index]]?.answerText,
    }));

    await submitSurvey({ surveyQuestions });
    // handleSkip();
  };

  return (
    <div className="bg-main pt-8 rounded-3xl">
      <div className="bg-white rounded-3xl shadow-lg px-8 py-4">
        {/* Progress Bar */}
        <div className="flex justify-center items-center mb-6 mt-4">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`h-1 flex-1 mx-1 rounded-full ${
                index <= currentStep ? 'bg-black' : 'bg-gray-300'
              }`}
            ></div>
          ))}
        </div>

        {loading && (
          <div className="my-4">
            <Loader roundLoader />
          </div>
        )}

        {!loading && error && <div className="text-tertiary">{t.Something_Wrong}</div>}

        {!loading && steps.length === 0 && <div className="text-tertiary">No Survey Questions Added</div>}

        {/* Question */}
        <h2 className="text-sm font-semibold mb-6">
          {steps[currentStep]?.questionText}
        </h2>

        {/* Options */}
        <div className="flex justify-center gap-2 mb-6">
          {steps[currentStep]?.answers?.map((option, index) => (
            <button
              key={index}
              onClick={() => handleSelectAnswer(index)}
              className={`flex flex-col items-center text-gray-700 ${
                answers[currentStep] === index
                  ? ' font-semibold text-black'
                  : 'hover:text-black'
              }`}
            >
              <div
                className={`p-1 rounded-md bg-gray-100 ${
                  answers[currentStep] === index ? 'border-2 border-black' : ''
                }`}
              >
                <img className="" src={option.iconUrl} alt="icon" />
                {/* <img
                  className=""
                  src={getEmojiByLabel(option.answerText)}
                  alt="icon"
                /> */}
              </div>
              <span className="text-sm mt-2">{option.answerText}</span>
            </button>
          ))}
        </div>
        {postError && <label className="text-tertiary">{postError}</label>}
        {postResponse && <label className="green">Thank you for your valuable time.</label>}
        {/* Navigation Buttons */}
        {steps.length > 0 && (
          <div className="flex justify-between items-center">
            <button onClick={handleSkip} className="text-black font-medium">
              {t.Skip}
            </button>
            <div className="flex justify-end items-center gap-4">
              {currentStep > 0 && (
                <button
                  onClick={handlePrevious}
                  className="text-black font-medium"
                >
                  {t.Previous}
                </button>
              )}
              {!isLastStep ? (
                <button
                  onClick={handleNext}
                  className={`bg-primary text-white px-2 py-2 rounded-full shadow-md${
                    answers[currentStep] == null
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  disabled={answers[currentStep] == null}
                >
                  {t.Next}
                </button>
              ) : (
                <button
                  onClick={() => (postResponse ? handleSkip() : handleSubmit())}
                  className={`bg-primary text-white px-2 py-2 rounded-full shadow-md${
                    answers[currentStep] == null
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  disabled={answers[currentStep] == null}
                >
                  {postLoading ? (
                    <Loader roundLoader size="small" />
                  ) : postResponse ? (
                    t.Close
                  ) : (
                    t.Submit
                  )}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackForm;
