import React, { useState, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

import Loader from '../../../UI/Loader';
import useFetchMedicationsByEpisode from '../../../../utilFunctions/completedAppointment/useFetchMedicationsByEpisode';
import CollapsibleWrapper from '../../CollapsibleWrapper';

import MedicationItem from './MedicationItem';

const Medications = () => {
  const { t, isRtl } = useSelector(selectLanguage);
  const { data: medicationData, loading, error } = useFetchMedicationsByEpisode();
  
  const formatedData = useMemo(() => {
    if (medicationData.length > 0) {
      return medicationData
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => {
          // Sort by order start date/time in descending order
          const timeA = new Date(`${a.order_start_date}T${a.order_start_time}`);
          const timeB = new Date(`${b.order_start_date}T${b.order_start_time}`);
          return timeB - timeA;
        })
    } else {
      return [];
    }
  }, [medicationData]);

  return (
    <CollapsibleWrapper title={t.Medications} expand={true}>
      <div className="flex flex-col">
        {medicationData?.length > 0 && (
          <>
            <ul>
              {formatedData && formatedData.length > 0 ? (
                formatedData.slice(0,4).map((item, index) => (
                  <li key={index} className="mb-2">
                    <MedicationItem index={index} item={item} />
                  </li>
                ))
              ) : (
                <div className="w-full flex justify-center">
                  <span className="mt-8">{t.NoRecord}</span>
                </div>
              )}
            </ul>
          </>
        )}
        {loading && (
          <div className="w-full justify-center">
            <Loader roundLoader />
          </div>
        )}
        {!loading && medicationData.length === 0 && (
          <div className="w-full flex justify-center">
            <span className="mt-8">{t.NoRecord}</span>
          </div>
        )}
      </div>
    </CollapsibleWrapper>
  );
};

export default memo(Medications);
