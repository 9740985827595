import React from 'react';
import { FiDownload } from 'react-icons/fi';
import StatusItem from '../Status';
import { getIconByCode } from '../../../../utilFunctions/Vitals';

import './prescriptions.css';
import { downloadPrescription } from '../../../../utilFunctions/completedAppointment/useFetchPrescriptionsByEpisode';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/languageSlice';

const PrescriptionItem = ({ index, item }) => {
  const { t, isRtl } = useSelector(selectLanguage);

  const {
    episode_number,
    episode_id,
    prescription_number,
    prescription_orders,
  } = item;

  const orders = prescription_orders.split(', ');

  const handleDownload=async()=>{
    await downloadPrescription(prescription_number)
  }

  return (
    <div className="presContainer w-full gap-4">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex gap-3">
          <img
            className="iconImg"
            src={`${process.env.PUBLIC_URL}/images/icons/medication.png`}
            alt="icon"
          />
          <div className="flex flex-col gap-1">
            <span className="pname">{t.PrescriptionNumber}</span>
            <span className="pvalue">{prescription_number}</span>
            <StatusItem />
          </div>
        </div>
        <div className="downloadButtonIcon" onClick={handleDownload}>
          <FiDownload />
        </div>
      </div>
      <div className="flex w-full gap-2 flex-col">
        {orders.map((order) => (
          <div className="listItem">
            <img
              src={`${process.env.PUBLIC_URL}/images/icons/reporticon.svg`}
              alt="icon"
            />
            <div className="text-sm">{order}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrescriptionItem;
