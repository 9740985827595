import React, { useEffect, useRef, useState } from 'react';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';



const DaysSlider = ({ selectedDate, setSelectedDate, days }) => {
  const daysContainerRef = useRef(null);

  // Scroll handlerr
  const scrollDays = (direction) => {
    if (daysContainerRef.current) {
      const scrollAmount = 150; // Adjust scroll amount as needed
      daysContainerRef.current.scrollBy({
        left: direction === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleDayChange = (day) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(day);
    setSelectedDate(newDate);
  };

  return (
    <div
      className="flex items-center gap-1 mb-1 rounded-lg px-1 py-2 justify-between"
      style={{ backgroundColor: '#FAFAFA' }}
    >
      {/* Previous Button */}
      <button
        onClick={() => scrollDays('prev')}
        className="p-2 bg-secondary text-white rounded-full"
      >
        <FiChevronLeft />
      </button>

      {/* Days List */}
      <div
        ref={daysContainerRef}
        className="flex gap-1 overflow-x-auto scrollbar-hide"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        {days.map(({ day, date }) => (
          <button
            key={date}
            onClick={() => handleDayChange(date)}
            className={`flex flex-col items-center px-2 py-1 rounded-lg ${
              selectedDate.getDate() === date
                ? 'bg-white text-blue-500'
                : 'text-gray-600'
            }`}
            style={{ scrollSnapAlign: 'start' }}
          >
            <span className="text-xs ">{day}</span>
            <span className="text-sm font-semibold">{date}</span>
          </button>
        ))}
      </div>

      {/* Next Button */}
      <button
        onClick={() => scrollDays('next')}
        className="p-2 bg-secondary text-white rounded-full"
      >
        <FiChevronRight />
      </button>
    </div>
  );
};

export default DaysSlider;
