import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseFetch } from '../../config';
import { selectAppointments } from '../../store/appointmentSlice';

function useSubmitSurvey() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { surveyAppointment } = useSelector(selectAppointments);
  const { hospitalCode } = useSelector(
    (state) => state.hospitalInfo.selectedHospital
  );

  const postData = async ({ surveyQuestions }) => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const bodyData = {
        hospitalCode: hospitalCode,
        episodeNumber: 'EP12345',
        appointmentDateTime: moment(
          surveyAppointment?.apptDateString
        ).toISOString(),
        appointmentId: surveyAppointment?.id,
        doctorName: 'Dr. John Doe',
        surveyQuestions,
        consultationType: 'Telemedicine',
        comments: 'The doctor was very professional and thorough.',
        platform: 'Web',
        rating: 5,
      };

      const apiResponse = await baseFetch(`api/feedback/survey`, {
        method: 'POST',
        body: bodyData,
      });

      if(!apiResponse || !apiResponse.status){
        throw new Error('Someting went wrong try later !')
      }

      if (apiResponse) {
        setData(apiResponse);
      }
    } catch (error) {
      setError('Someting went wrong try later !');
      console.error('Error submitting data:', error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, submitSurvey: postData };
}

export default useSubmitSurvey;
