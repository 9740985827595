import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { PiCheckDuotone } from 'react-icons/pi';
import UpcomingAppoitmentSlider from '../components/BookAppointment/UpcomingAppoitmentSlider';
import Toolbar from '../components/BookAppointment/ToolBar';
import DayDates from '../components/BookAppointment/DayDates';
import DoctorList from '../components/BookAppointment/DoctorList';
import RightSidebar from '../components/BookAppointment/RightSidebar';
import { ClinicAndSymptomsList } from '../utilFunctions/BookAppointmentDocList';
import getDoctorListBySpecialityCode, {
  getLastVisitedDoctors,
  getPrimaryCareDoctors,
} from '../utilFunctions/bookAppointment/getDoctorListBySpecialityCode';
import Loader from '../components/UI/Loader';
import { getDoctorsList } from '../utilFunctions/bookAppointment/getDoctorsList';
import { getUpcomingAppointments } from '../utilFunctions/getUpcomingAppointments';
import { bookAppointmentActions, modalActions } from '../store';
import { envVariables } from '../config';
import { selectLanguage } from '../store/languageSlice';
import {
  fetchUpcommingAppointments,
  selectBookedAppointments,
} from '../store/appointmentSlice';
import Modal from '../components/Modal/Modal';
import generatePaymentUrl, { settlePaymentBill, verifyPayment } from '../utilFunctions/bookAppointment/generatePaymentUrl';
import getServicePrice from '../utilFunctions/bookAppointment/getServicePrice';
import { getTValue } from '../translations/common';
import CustomeModal from '../components/Modal/CustomeModal';
import useBreakpoint from '../utilFunctions/useBreakpoint';
import SelectedDoctorCard from '../components/BookAppointment/SelectedDoctorCard';
import { fetchClinicnsAndSymptomsList, selectBadges } from '../store/badgesSlice';
import "../components/BookAppointment/style.css"

export const BookAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const params = useParams();
  const location = useLocation();
  const viewPortRef = useRef(null);
  const { isMobile } = useBreakpoint();
  // const {doctorsInfoArray, clinicsList, symptomsList} = useLoaderData();
  const { clinicsList, symptomsList } = useSelector(selectBadges);
  // console.log('payload booking' ,{clinicsList,symptomsList})
  const { t, isRtl } = useSelector(selectLanguage);
  const [doctorsBasicInfoArray, setdoctorsBasicInfoArray] = useState([]);
  // const [clinicsList, setclinicsList] = useState([]);
  // const [symptomsList, setsymptomsList] = useState([]);
  // const [doctorsInfoArray, setdoctorsInfoArray] = useState([]);
  // const modalType = useSelector((state) => state.modal.modalType);
  const [showLoader, setShowLoader] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const [paymentProcessModal, setPaymentProcessModal] = useState({
    title:'',
    message:'',
    isLoading:false,
    show:false,
  });

  const hospitalId = useSelector((state) => state.hospitalInfo.hospId);
  const hospitalCode = useSelector((state) => state.hospitalInfo.hospCode);
  const hospitalName = useSelector((state) => state.hospitalInfo.hospName);
  const hospitalList = useSelector((state) => state.hospitalInfo.list);

  // const availableDatesArray = useSelector(
  //   (state) => state.bookAppointment.availableDatesArray
  // );
  // const [bookedAppointments, setBookedAppointments] = useState(null);
  const bookedAppointments = useSelector(selectBookedAppointments);
  // const filterTypeValue = useSelector(state => state.searchbar.searchByClinic);
  const specialityCode = useSelector((state) => state.searchbar.specialityCode);
  const selectedDocCode = useSelector((state) => state.searchbar.docCode);
  const clickedCardDocCode = useSelector((state) => state.bookAppointment.docCode)
  const { lastVisitedDocs, primaryCareDocs, primaryOrLastVisited } =
    useSelector((state) => state.bookAppointment);
  const [selectedClinicDocsList, setSelectedClinicDocsList] = useState(null);
  const [originalselectedClinicDocsList, setoriginalselectedClinicDocsList] =
    useState(null);
  const [searchedDoctorArray, setSearchedDoctorArray] = useState([]);
  // const isFirstRender = useRef(true);
  const [servicePriceInfo, setServicePriceInfo] = useState(null);
  const [loadingPriceINfo, setLoadingPriceINfo] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [canProceedToPayment, setCanProceedToPayment] = useState(true);
  const [selectedDoc, setSelectedDoc] = useState();
  const [selectedHospInfo, setSelectedHospInfo] = useState(JSON.parse(
    localStorage.getItem('selectedHopitalInfo')
  ));
  
  const availableDatesHandler = (datesArray) => {
    if(datesArray.length === 0 ){
      dispatch(bookAppointmentActions.docAvailableDates(null));
      dispatch(bookAppointmentActions.dateSelector(null));
      dispatch(bookAppointmentActions.updateSelectedMonthDates(null));
    }else {
      dispatch(bookAppointmentActions.docAvailableDates(datesArray));
    }
 
  };

  const doctorsList = async () => {
    const doctorsList = await getDoctorsList();
    setdoctorsBasicInfoArray(doctorsList);
    setSearchedDoctorArray(doctorsList);
    setShowLoader(false);
  };

  const upcomingAppointmentsList = async () => {
    // const list = await getUpcomingAppointments();
    dispatch(fetchUpcommingAppointments());
    // setBookedAppointments(list);
  };

  const populateAllList = async () => {
    // const { clinicsList: clinics, symptomsList: symptoms } =
    //   await clinicAndSymptomsList();
    dispatch(fetchClinicnsAndSymptomsList())

    const lastVisitedDoctors = await getLastVisitedDoctors();
    dispatch(bookAppointmentActions.updateLastVisitedDocs(lastVisitedDoctors));
    const primaryCareDoctors = await getPrimaryCareDoctors();
    dispatch(bookAppointmentActions.updatePrimaryCareDocs(primaryCareDoctors));

    // console.log('yy populateAllList', {
    //   lastVisitedDoctors,
    //   primaryCareDoctors,
    // });

    // setclinicsList(clinics);
    // setsymptomsList(symptoms);
  };

  const handleSearchDoctors = (text, item) => {
    const { code, docCode } = item;
    let specialityCode1 = code;
    let selectedDocCode1 = docCode;
  
    let filteredDocs = [];
    let searchTerms = text.toLowerCase().split(' ').filter(term => term);

    const matchesSearchTerms = (doctor, isRtl) => {
      return searchTerms.some(term => 
        isRtl 
          ? doctor.docNameAR.toLowerCase().includes(term) 
          : doctor.docName.toLowerCase().includes(term)
      );
    };
  
    if (specialityCode1 && !selectedDocCode1 && text.length > 0) {
      filteredDocs = doctorsBasicInfoArray.filter((doctor) => {
        return (
          matchesSearchTerms(doctor, isRtl) &&
          doctor.specialityCode === specialityCode1
        );
      });
    } else if (specialityCode1 && selectedDocCode1 && text.length > 0) {
      let selectedClinicDocsListClone = [...originalselectedClinicDocsList];
      let doctorObject = selectedClinicDocsListClone.filter((doctor) => {
        return doctor.providerCode === selectedDocCode1;
      });
      setSelectedClinicDocsList(doctorObject);
    } else if (specialityCode1 && !selectedDocCode1 && text.length === 0) {
      filteredDocs = doctorsBasicInfoArray.filter((doctor) => {
        return doctor.specialityCode === specialityCode1;
      });
    } else if (specialityCode1 && selectedDocCode1 && text.length === 0) {
      let selectedClinicDocsListClone = [...originalselectedClinicDocsList];
      let doctorObject = selectedClinicDocsListClone.filter((doctor) => {
        return doctor.providerCode === selectedDocCode1;
      });
  
      setSelectedClinicDocsList(doctorObject);
    } else if (!item.code && text.length > 0) {
      filteredDocs = doctorsBasicInfoArray.filter((doctor) => 
        matchesSearchTerms(doctor, isRtl)
      );
    } else if (text === '') {
      filteredDocs = doctorsBasicInfoArray;
    }

    setSearchedDoctorArray(filteredDocs);
  };

  const appointmentConfirmationHandler = async (selectedDoc, appointmentServicesPrice) => {
    setSelectedDoc(selectedDoc);
    // dispatch(modalActions.toggle(''));
    setTimeout(() => {
      setShowServiceModal(true);
    }, 200);
    setLoadingPriceINfo(true);
    let servicesPrice = appointmentServicesPrice;
    if(!servicesPrice) {
      servicesPrice = await getServicePrice(
        selectedDoc?.selectedSlotId,
        hospitalId
      );
    }
    setServicePriceInfo(servicesPrice);
    setLoadingPriceINfo(false);
    // const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || selectedDoc?.appointmentType == "Offline" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
    // const allowedToProceed = false;
       
    if (selectedDoc?.appointmentType === 'Online') {
      const allowedToProceed= servicesPrice?.is_final_price || servicesPrice?.is_final_price == "true" || (!servicesPrice?.is_final_price && servicesPrice?.patientShare == servicesPrice?.orderPrice)
      setCanProceedToPayment(allowedToProceed);
      if (allowedToProceed) {
      const paymentUrlRes = await generatePaymentUrl(
        selectedDoc?.selectedSlotId,
        servicesPrice,
        selectedDoc?.selectedDate,
        selectedDoc?.docName,
        hospitalId
      );
      
      setPaymentUrl(paymentUrlRes?.data.url);
      }
    }
  };

  const closebtnClickHandler = () => {
    // dispatch(fetchUpcommingAppointments())
    setShowServiceModal(false); 
  };

  const payNowClickHandler = () => {
    setTimeout(() => {
      setShowServiceModal(false);
    }, 200);

    window.location.href = paymentUrl;
  };

  useEffect(() => {
    // if (isFirstRender.current) {
    //   isFirstRender.current = false;
    //   return;
    // }
    setShowLoader(true);
    // dispatch(fetchClinicnsAndSymptomsList())
    doctorsList();
    upcomingAppointmentsList();
    populateAllList();

    const selectedHospital = hospitalList?.find((x) => x.hospitalCode == hospitalCode);
    setSelectedHospInfo(selectedHospital)
  }, [hospitalCode]);

  useEffect(() => {
    if (specialityCode && !selectedDocCode) {
      (async () => {
        setShowLoader(true);
        const doctorsList = await getDoctorListBySpecialityCode(
          specialityCode,
          hospitalId
        );
        setShowLoader(false);
        setSelectedClinicDocsList(doctorsList);
        setoriginalselectedClinicDocsList(doctorsList);
      })();
    } else if (specialityCode && selectedDocCode) {
      (async () => {
        setShowLoader(true);
        const doctorsList = await getDoctorListBySpecialityCode(
          specialityCode,
          hospitalId
        );
        const doctorObject = doctorsList.filter((doctor) => {
          return doctor.providerCode === selectedDocCode;
        });

        setShowLoader(false);
        setSelectedClinicDocsList(doctorObject);
        setoriginalselectedClinicDocsList(doctorObject);
      })();
    }
  }, [specialityCode]);

  useEffect(() => {
    if (primaryOrLastVisited && primaryOrLastVisited === 'visited') {
      (async () => {
        setSelectedClinicDocsList(lastVisitedDocs);
        // setSearchedDoctorArray(lastVisitedDocs);
      })();
    } else if (primaryOrLastVisited && primaryOrLastVisited === 'primary') {
      (async () => {
        setSelectedClinicDocsList(primaryCareDocs);
        // setSearchedDoctorArray(primaryCareDocs);
      })();
    }
  }, [primaryOrLastVisited]);
  // const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    // Extracting payment status from the URL path
    const path = location.pathname;
    const segments = path.split('/');
    const status = segments[segments.length - 1];

    // console.log("location", location)

    // const { status } = params;

    // console.log("status", status)

    // Now status will contain "authorized" or "declined" or any other status
    setPaymentStatus(status);

    // Remove the status segment from the URL and redirect to /book-appointment
    if (status === 'authorized' || status === 'declined') {
      navigate('/book-appointment', { replace: true });
    }
  }, []);

  const handlePaymentVerification = async()=>{
    if(paymentStatus === 'declined') {
      // alert(paymentStatus);
      setPaymentProcessModal({
        title:t.Error,
        message:t.PaymentDeclined,
        isLoading:false,
        show:true,
      });
      return ;
    }

    if(paymentStatus === 'authorized') {
      setPaymentProcessModal({
        title:t.PleaseWait,
        message:t.ProcessingMessage,
        isLoading:true,
        show:true,
      });
      const verifyData = await verifyPayment();
      const amount = verifyData?.data?.order?.amount;
      const transactionId = verifyData?.data?.order?.transaction?.ref;
      // if(!transactionId || !verifyData?.status || verifyData?.status !== "true") {
        if(!transactionId) {
        setPaymentProcessModal({
          title:t.Error,
          message:t.PaymentFailed,
          isLoading:false,
          show:true,
        });

        return;
      }
      const settledData= await settlePaymentBill(transactionId,amount);

      if (settledData?.status !== "Success") {
        setPaymentProcessModal({
          title:t.Error,
          message:t.PaymentFailed,
          isLoading:false,
          show:true,
        });

        return;
      }
      setPaymentProcessModal({
        title:'Success',
        message:t.SuccessMessage,
        isLoading:false,
        show:true,
      });
      // refech To updateState
      upcomingAppointmentsList()
      setPaymentStatus(null)
    }
  }

  useEffect(() => {
    // if (paymentStatus === 'authorized' || paymentStatus === 'declined') {
    //   alert(paymentStatus);
    // }
    handlePaymentVerification()
  }, [paymentStatus]);

  useEffect(() => {
    viewPortRef.current = document.querySelector('.docsListSide');
  }, []);

  const MobileSideBar = () => {
    return (
      <div dir='rtl' className="flex w-full mt-4">
        {bookedAppointments && bookedAppointments.length > 0 && (
          <UpcomingAppoitmentSlider
            title= {t.Upcoming}
            updateAppointmentsFunc={upcomingAppointmentsList}
            doctorsInfoList={doctorsBasicInfoArray}
            bookedAppointments={bookedAppointments}
            appointmentConfirmationHandler={appointmentConfirmationHandler}
            handleSearchDoctors={handleSearchDoctors}
          />
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="flex w-full gap-4 flex-col min-[1025px]:flex-row fndDocBx">
        <div className="flex-auto md:w-7/12 w-3/4 srchSide">
          <h1 className={`page-title bkApt`}>
            {t.BookAppointment}
          </h1>
          <Toolbar
            clinicsList={clinicsList}
            symptomsList={symptomsList}
            handleSearchDoctors={handleSearchDoctors}
          />
          {isMobile && <MobileSideBar />}          
        </div>
        {!isMobile && bookedAppointments && bookedAppointments.length > 0 && (
            <div className="upcomAptSide flex-auto w-1/12 max-[1170px]:w-6/12 max-[1280px]:w-4/12 max-[1420px]:w-3/12 max-[1620px]:w-2/12 min-w-52 rounded-md">
              <UpcomingAppoitmentSlider
                title= {t.Upcoming}
                updateAppointmentsFunc={upcomingAppointmentsList}
                doctorsInfoList={doctorsBasicInfoArray}
                bookedAppointments={bookedAppointments}
                appointmentConfirmationHandler={appointmentConfirmationHandler}
                handleSearchDoctors={handleSearchDoctors}
              />
            </div>
          )}
      </div>
      <div className={`flex w-full docsListAndInfo ${clickedCardDocCode ? "infoActive" : ""}`}>
        <div className="docsListSide">
          {
            clickedCardDocCode && (
              <SelectedDoctorCard  availableDatesHandler={availableDatesHandler} docExtraDetails={selectedClinicDocsList} />
            )
          }
          {/* <h2
            className="text-24 font-medium mb-4 mt-4"
            style={{ color: '#474747' }}
          >
            {t.DoctorsList}
          </h2> */}
          {/* {availableDatesArray ? <DayDates isRtl={isRtl} /> : null} */}
          <div className={`ba-docLst${showLoader ? " !block" : ""}`} ref={viewPortRef}>
            {showLoader && (
              <div className="my-4 w-full">
                <Loader roundLoader />
              </div>
            )}
            {!showLoader &&
              specialityCode &&
              selectedClinicDocsList &&
              selectedClinicDocsList.length > 0 && (
                <DoctorList
                  doctorsInfoList={searchedDoctorArray}
                  extraDetails={selectedClinicDocsList}
                  availableDatesHandler={availableDatesHandler}
                  rootElement={viewPortRef.current}
                  handleSearchDoctors={handleSearchDoctors}
                />
              )}
            {!showLoader &&
              !specialityCode &&
              primaryOrLastVisited &&
              selectedClinicDocsList &&
              selectedClinicDocsList.length > 0 && (
                <DoctorList
                  doctorsInfoList={searchedDoctorArray}
                  extraDetails={selectedClinicDocsList}
                  availableDatesHandler={availableDatesHandler}
                  rootElement={viewPortRef.current}
                  handleSearchDoctors={handleSearchDoctors}
                />
              )}
            {!showLoader &&
              specialityCode &&
              selectedClinicDocsList &&
              !selectedClinicDocsList.length > 0 && <p>{t.NoResultsfound}</p>}
            {!showLoader && !specialityCode && !primaryOrLastVisited && (
              <DoctorList
                doctorsInfoList={searchedDoctorArray}
                availableDatesHandler={availableDatesHandler}
                rootElement={viewPortRef.current}
                handleSearchDoctors={handleSearchDoctors}
              />
            )}
          </div>
        </div>
        {/* <RightSidebar doctorsInfoList={doctorsBasicInfoArray} /> */}
      </div>
      {showServiceModal && (
        <CustomeModal
          title={servicePriceInfo?.orderPrice ? t.Confirmed: t.Error}
          width="md"
          isOpen={true}
          onCancel={closebtnClickHandler}
          cancelText={paymentUrl ? t.PayLater : t.Close}
          onConfirm={(canProceedToPayment && paymentUrl) ? payNowClickHandler : null}
          confirmText={t.PayNow}
        >
          {servicePriceInfo?.orderPrice && <div className="alert success bg-secondary">
            <p className="text-16 font-medium">{t.ConfirmedMessage}</p>
          </div>}
          {servicePriceInfo && servicePriceInfo?.orderPrice ? (
            <ul className="flex flex-col gap-2 mt-3">
              <li>
                {t['Hospital']}:{' '}
                {isRtl ? selectedHospInfo?.hospitalName_ar : hospitalName}
              </li>
              <li>
                {t['Clinic']}:{' '}
                {getTValue(
                  selectedDoc,
                  'specialityDescriptionAR',
                  'specialityDescription',
                  isRtl
                )}
              </li>
              <li>
                {t['Physician']}:{' '}
                {isRtl ? selectedDoc?.docNameAr : selectedDoc?.docName}
              </li>
              <li>
                {t['Date']}: {selectedDoc?.selectedDate}
              </li>
              <li>
                {t['Time']}: {selectedDoc?.selectedSlotTime}
              </li>
              <li>
                {t['ConsultationType']}:
                {selectedDoc?.appointmentType === 'Offline'
                  ? t.InPerson
                  : t.Telemedicine}
              </li>
              <li>
                {t['PatientPay']}: {servicePriceInfo?.patientShare}
              </li>
              <li>
                {t['TotalAmount']}: {servicePriceInfo?.orderPrice}
              </li>
              {!canProceedToPayment && <p className="errorMsg my-4">
                    <span>{t.InsuranceMessage}</span>
                  </p>}
            </ul>
            
          ) : loadingPriceINfo ? (
            <div className="my-4">
              <Loader roundLoader />
            </div>
          ) : (
            <div className="my-4">{t.CouldNotloaddata}</div>
          )}
          {/* {paymentUrl ? (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.PayLater}
              </button>
              <button
                className="bg-secondary text-white btn"
                onClick={payNowClickHandler}
              >
                {t.PayNow}
              </button>
            </div>
          ) : (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.Close}
              </button>
            </div>
          )} */}
        </CustomeModal>
      )}
      {paymentProcessModal.show && (
        <CustomeModal
          title={paymentProcessModal.title}
          isOpen={true}
          icon={paymentProcessModal.title == 'Success' ? <PiCheckDuotone /> : null}
          cancelText={t.ok}
          onCancel={()=>{
            // if(paymentProcessModal.title == 'Success') {
            //   upcomingAppointmentsList()
            // }
            setPaymentProcessModal({
              title:'',
              message:'',
              isLoading:false,
              show:false,
            })
          }}
          
          // cancelText={paymentProcessModal.isLoading ? t.PayLater : t.Close}
        >

          { paymentProcessModal.isLoading && (
              <Loader roundLoader />
          )}
           <div className="my-4">{paymentProcessModal.message}</div>
          {/* {paymentUrl ? (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.PayLater}
              </button>
              <button
                className="bg-secondary text-white btn"
                onClick={payNowClickHandler}
              >
                {t.PayNow}
              </button>
            </div>
          ) : (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.Close}
              </button>
            </div>
          )} */}
        </CustomeModal>
      )}
      {/* {modalType === 'ServicePriceModal2' && (
        <Modal
          title={t.Confirmed}
          width="md"
          // mainClass="priceModal"
          onClose={closebtnClickHandler}
        >
          <div className="alert success bg-secondary">
            <p className="text-16 font-medium">{t.ConfirmedMessage}</p>
          </div>
          {servicePriceInfo && servicePriceInfo?.orderPrice ? (
            <ul className="appntInfo mt-3">
              <li>
                {t['Hospital']}:{' '}
                {isRtl ? selectedHospInfo?.hospNameAr : selectedHospInfo?.hospName }
              </li>
              <li>
                {t['Clinic']}:{' '}
                {getTValue(
                  selectedDoc,
                  'specialityDescriptionAR',
                  'specialityDescription',
                  isRtl
                )}
              </li>
              <li>
                {t['Physician']}:{' '}
                {isRtl ? selectedDoc?.docNameAr : selectedDoc?.docName}
              </li>
              <li>
                {t['Date']}: {selectedDoc?.selectedDate}
              </li>
              <li>
                {t['Time']}: {selectedDoc?.selectedSlotTime}
              </li>
              <li>
                {t['ConsultationType']}:
                {selectedDoc?.appointmentType === 'Offline' ? t.InPerson : t.Telemedicine}
              </li>
              <li>
                {t['PatientPay']}: {servicePriceInfo?.patientShare}
              </li>
              <li>
                {t['TotalAmount']}: {servicePriceInfo?.orderPrice}
              </li>
            </ul>
          ) : loadingPriceINfo ? (
            <div className="my-4">
              <Loader roundLoader />
            </div>
          ) : (
            <div className="my-4">{t.CouldNotloaddata}</div>
          )}
          {paymentUrl ? (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.PayLater}
              </button>
              <button
                className="bg-secondary text-white btn"
                onClick={payNowClickHandler}
              >
                {t.PayNow}
              </button>
            </div>
          ) : (
            <div className="btns flex flex-wrap justify-end gap-x-3 mt-4">
              <button
                className="bg-tertiary text-white btn"
                onClick={closebtnClickHandler}
              >
                {t.Close}
              </button>
            </div>
          )}
        </Modal>
      )} */}
    </Fragment>
  );
};

export const clinicAndSymptomsList = async () => {
  const localClinicsList = localStorage.getItem('clinicsList');
  const localsymptomsList = localStorage.getItem('symptomsList');

  if (!localClinicsList || localClinicsList == 'undefined') {
    return await ClinicAndSymptomsList();
  } else {
    const clinicsList = JSON.parse(localClinicsList);
    const symptomsList = JSON.parse(localsymptomsList);
    return { clinicsList, symptomsList };
  }
};
